import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, PageHeader, Result, Row, notification } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { RootState } from 'App/state/root.reducer';
import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import { changePassword, getAccountDetails } from 'App/state/account/account.thunk';
import { ChangePasswordRequest } from 'App/api/endpoints/account/requests';
import ChangePasswordForm from './components/ChangePasswordForm';
import './ChangePasswordContainer.less';

const { LOADING } = StatusType;

const ChangePasswordContainer: React.FC<{}> = () => {

	const history = useHistory();
	const dispatch = useDispatch();

	const { t } = useTranslation(['page', 'common']);

	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);
	const changePasswordStatus = useSelector((state: RootState) => state.account.status.changePassword);


	useEffect(() => {
		if (!accountDetails)
			dispatch(getAccountDetails());
	}, [dispatch, accountDetails]);

	const accountStatus = useSelector((state: RootState) => state.account.status);


	const onFinish = (values: ChangePasswordRequest) => {
		const onSuccess = () => {
			history.push('/account')

			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPage.EditProfileContainer.ChangePasswordSuccess')
			});
		};

		dispatch(changePassword(values, onSuccess));
	};

	return accountStatus.updateAccountDetails === LOADING ? (
		<LoadingScreen container='screen' />
	) : accountDetails ? (
		<React.Fragment>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} className="change-password-container-image-block">
				<Row justify='center' align='middle' style={{ height: '100%' }}>
					<span className="change-password-container-image-block-text">Nowe hasło</span>
				</Row>
			</Col>
			<Row align='middle' justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title='Zmiana hasła' />
						</Col>
					</Row>
					<ChangePasswordForm
						onFinish={onFinish}
						loading={changePasswordStatus === LOADING}
					/>
				</Col>
			</Row>
		</React.Fragment>
	) : (
		<Result
			status='404'
			title={t('common:Errors.AnErrorOccured')}
			subTitle={t('common:Errors.CompanyNotFound')}
			extra={
				<Button type='primary' onClick={() => history.push('/')}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		/>
	);
};

export default ChangePasswordContainer;