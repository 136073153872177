import { Cart } from "App/domain/entities/cart";
import { CartProduct } from "App/domain/entities/cart_product";
import { Promotion } from "App/domain/entities/promotion";
import { Restaurant } from "App/domain/entities/restaurant";
import { cartService } from "./cartService";

export const promotionService = {
    getDiscountFromValue(totalCartPrice: number, discountType: string, discount: number) {
        switch (discountType) {
            case 'PERCENT':
                return totalCartPrice * discount / 100;
            case 'PLN':
                return discount;
            default:
                return 0;
        }
    },

    getTextToDisplay(promotion: Promotion) {
        let textToShow = "-" + promotion.discount.toString()
        if (promotion.discount_type === 'PLN')
            textToShow += " PLN"
        else
            textToShow += "%"

        return textToShow;
    },

    isValidForCartProducts(promotion: Promotion, cartProducts: CartProduct[]) {
        //jesli w promocji nie ma zadnych produktów to znaczy ze obejmuje wszystkie
        if (promotion.promotion_products.length === 0)
            return true;

        let validationResult = false;

        cartProducts.forEach((cartProduct) => {
            //jesli w koszyczku znajduje się produkt który jest w promocji to zwróć true
            validationResult = validationResult || promotion.promotion_products.some(product => product.product.id === cartProduct.product.id);
        })

        return validationResult;
    },

    isValidForRestaurant(promotion: Promotion, restaurant: Restaurant) {
        //jesli w promocji nie ma zadnych restauracji to znaczy ze obejmuje wszystkie
        return (promotion.promotion_places.length === 0 ||
            //jesli restaruacja z koszyczka jest w liscie restauracji to zwroc true
            promotion.promotion_places.some(promotionRestaurant => promotionRestaurant.place.id === restaurant.id))
    },

    isValidForMinimalTotalCartValue(promotion: Promotion, cart: Cart) {
        return promotion.minimal_cart_value_in_pln <= cartService.getCartPrice(cart);
    }



}