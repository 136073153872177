import { Input, Button, Form, Row, Col, message } from 'antd';
import { FormProps } from 'antd/lib/form';
import { wroclawGeolocation } from 'App/api/agent/axios/configuration';
import OpenStreetMap from 'App/common/components/OpenStreetMap/OpenStreetMap';
import { useFirstRender } from 'App/common/utils/useFirstRenderHook';
import { geolocationService } from 'App/services/geolocationService';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addUserAddressFormRules } from '../../utils/addUserAddressFormRules';
import { layout, layoutModal, tailLayout, tailLayoutModal } from './utils/addUserAddressFormUtils';

interface AddUserAddressFormProps extends FormProps {
	modal?: boolean;
	userLoggedIn: boolean;
}

const AddUserAddressForm: React.FC<AddUserAddressFormProps> = (props: AddUserAddressFormProps) => {
	const { t } = useTranslation(['form', 'common']);
	const firstRender = useFirstRender();

	const [address, setAddress] = useState('');
	const [foundAddress, setFoundAddress] = useState(null);
	const [addressGeolocation, setAddressGeolocation] = useState(null);
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const { modal, userLoggedIn, ...rest } = props;

	useEffect(() => {
		let timeOutId = null;
		if (!firstRender) timeOutId = setTimeout(() => searchAddressGeolocation(address), 1500);
		return () => clearTimeout(timeOutId);
	}, [address, firstRender]);

	const searchAddressGeolocation = (address) => {
		setLoading(true);
		geolocationService.getGeolocationFromAddress(address)?.then((cords) => {
			if (cords) {
				setAddressGeolocation(cords);
				setFoundAddress(true);
				setLoading(false);
			} else {
				setFoundAddress(false);
				message.warning('Nie znaleziono adresu na mapie');
				setLoading(false);
			}
		});
	};

	const handleFormValuesChange = (changedValues, values) => {
		if (values.address_1 && values.city) {
			setAddress(values.address_1 + ' ' + values.city);
		}
	};

	let correctLayout = modal ? layoutModal : layout;
	let correctTailLayout = modal ? tailLayoutModal : tailLayout;

	return (
		<Form
			className='px-3'
			{...correctLayout}
			{...rest}
			form={form}
			onValuesChange={handleFormValuesChange}
			labelAlign={modal ? 'left' : 'right'}
		>
			{/* Nazwa */}
			{userLoggedIn && (
				<Form.Item
					name='name'
					label={t('form:Common.User.Labels.AddressName')}
					rules={addUserAddressFormRules.name()}
					messageVariables={{ arg: t('form:Common.User.Labels.AddressName') }}
				>
					<Input />
				</Form.Item>
			)}

			{/* Adres 1 */}
			<Form.Item
				name='address_1'
				label={t('form:Common.User.Labels.Address1')}
				rules={addUserAddressFormRules.address_1()}
				messageVariables={{ arg: t('form:Common.User.Labels.Address1') }}
			>
				<Input />
			</Form.Item>

			{/* Adres 2 */}
			<Form.Item
				name='address_2'
				label={t('form:Common.User.Labels.Address2')}
				rules={addUserAddressFormRules.address_2()}
				messageVariables={{ arg: t('form:Common.User.Labels.Address2') }}
			>
				<Input />
			</Form.Item>

			{/* Miasto */}
			<Form.Item
				name='city'
				label={t('form:Common.User.Labels.City')}
				rules={addUserAddressFormRules.city()}
				messageVariables={{ arg: t('form:Common.User.Labels.City') }}
			>
				<Input />
			</Form.Item>

			{/* Komentarz */}
			<Form.Item
				name='comment'
				label={t('form:Common.User.Labels.Comment')}
				rules={addUserAddressFormRules.comment()}
				messageVariables={{ arg: t('form:Common.User.Labels.Comment') }}
			>
				<Input />
			</Form.Item>
			<Form.Item className='ant-row-center'>
				<Row justify='center'>
					<Col style={{ width: '100%', height: '300px' }}>
						<OpenStreetMap
							center={addressGeolocation || wroclawGeolocation}
							markerPosition={addressGeolocation || wroclawGeolocation}
							address={address}
							zoom={30}
						/>
					</Col>
				</Row>
			</Form.Item>

			<Form.Item {...correctTailLayout}>
				<Button block loading={loading} disabled={!foundAddress} type='primary' htmlType='submit'>
					{t('common:Actions.Save')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default AddUserAddressForm;
