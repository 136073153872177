import { AppThunk } from '../store';
import {
	loginStart,
	loginSuccess,
	loginFailure,
	logoutStart,
	cleanUpAuthStatusStart,
	registerFailure,
	registerStart,
	registerSuccess,
	refreshTokenStart,
	refreshTokenSuccess,
	refreshTokenFailure,
	loginFinish,
} from './auth.slice';
import { LoginRequest, RegisterRequest } from 'App/api/endpoints/auth/requests';
import agent from 'App/api/agent/agent';
import { RefreshTokenResponse } from 'App/api/endpoints/auth/responses';
import { clearAccountDetails, getAccountDetails } from '../account/account.thunk';
import { localStorageHelper } from 'App/common/utils/localStorageHelper';
import { clearNewOrderState } from '../newOrder/newOrder.slice';

export const login = (payload: LoginRequest, onSuccess?: () => void, onFailure?: () => void): AppThunk => async (
	dispatch
) => {
	dispatch(loginStart());
	agent.Auth.login(payload)
		.then((response) => {
			dispatch(loginSuccess(response));
			dispatch(getAccountDetails(() => dispatch(loginFinish())));
			if (onSuccess) onSuccess();
		})
		.catch((error) => {
			if (onFailure) onFailure();
			dispatch(loginFailure());
		});
};

export const logout = (onSuccess?: () => void): AppThunk => async (dispatch) => {
	dispatch(logoutStart());
	localStorage.removeItem('returnUrl');
	localStorage.removeItem('refresh_token');
	localStorageHelper.clear();
	dispatch(clearNewOrderState())
	dispatch(clearAccountDetails());
	if (onSuccess) onSuccess();
};

export const register = (payload: RegisterRequest, onSuccess?: () => void): AppThunk => async (dispatch) => {
	dispatch(registerStart());
	agent.Auth.register(payload)
		.then(() => {
			dispatch(registerSuccess());
			if (onSuccess) onSuccess();
		})
		.catch(() => {
			dispatch(registerFailure());
		});
};

export const refreshToken = (onSuccess?: Function, onFailure?: Function): AppThunk => async (dispatch) => {
	dispatch(refreshTokenStart());
	agent.Auth.refreshToken()
		.then((response: { data: RefreshTokenResponse }) => {
			dispatch(refreshTokenSuccess(response.data));
			if (onSuccess) onSuccess();
		})
		.catch(() => {
			dispatch(refreshTokenFailure());
			if (onFailure) onFailure();
		});
};

export const cleanUpAuthStatus = (): AppThunk => async (dispatch) => {
	dispatch(cleanUpAuthStatusStart());
};
