import { combineReducers } from '@reduxjs/toolkit';
import accountSlice from './account/account.slice';

import authSlice from './auth/auth.slice';
import newOrderSlice from './newOrder/newOrder.slice';
import ordersSlice from './orders/orders.slice';
import productCategoriesSlice from './productCategories/productCategories.slice';
import promotionsSlice from './promotions/promotions.slice';
import restaurantsSlice from './restaurants/restaurants.slice';

const rootReducer = combineReducers({
	auth: authSlice.reducer,
	account: accountSlice.reducer,
	restaurants: restaurantsSlice.reducer,
	productCategories: productCategoriesSlice.reducer,
	newOrder: newOrderSlice.reducer,
	promotions: promotionsSlice.reducer,
	orders: ordersSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
