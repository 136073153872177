import { Input, Button, Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { editProfileFormRules } from '../../utils/editProfileFormRules';

interface EditProfileFormProps extends FormProps {
	loading: boolean;
}

const EditProfileForm: React.FC<EditProfileFormProps> = (props: EditProfileFormProps) => {
	const { t } = useTranslation(['form', 'common']);

	const layout = {
		labelCol: {
			xs: {
				span: 24
			},
			md: {
				span: 8
			}
		},
		wrapperCol: {
			xs: {
				span: 24
			},
			md: {
				span: 8
			}
		}
	};

	const tailLayout = {
		wrapperCol: {
			xs: {
				span: 24
			},
			md: {
				span: 8,
				offset: 8
			}
		}
	};

	const { loading, ...rest } = props;

	return (
		<Form className='px-3' {...layout} {...rest}>

			{/* Imie */}
			<Form.Item
				name='name'
				label={t('form:Common.User.Labels.FirstName')}
				rules={editProfileFormRules.firstName()}
				messageVariables={{ arg: t('form:Common.User.Labels.FirstName') }}
			>
				<Input placeholder={t('form:Common.User.Placeholders.FirstName')} />
			</Form.Item>

			{/* Nazwisko */}
			<Form.Item
				name='last_name'
				label={t('form:Common.User.Labels.LastName')}
				rules={editProfileFormRules.lastName()}
				messageVariables={{ arg: t('form:Common.User.Labels.LastName') }}
			>
				<Input placeholder={t('form:Common.User.Placeholders.LastName')} />
			</Form.Item>

			{/* Telefon */}
			<Form.Item
				name='new_phone_number'
				messageVariables={{ arg: t('form:Common.User.Labels.PhoneNumber') }}
				label={t('form:Common.User.Labels.PhoneNumber')}
				rules={editProfileFormRules.phoneNumber()}
			>
				<Input placeholder={t('form:Common.User.Placeholders.PhoneNumber')} />
			</Form.Item>

			<Form.Item {...tailLayout}>
				<Button block loading={loading} type='primary' htmlType='submit'>
					{t('common:Actions.Save')}
				</Button>
			</Form.Item>

		</Form>
	);
};

export default EditProfileForm;
