import { Col, Row } from 'antd';
import { cartService } from 'App/services/cartService';
import { RootState } from 'App/state/root.reducer';
import React from 'react';
import { useSelector } from 'react-redux';
import ProductCard from './productCard/ProductCard';
import './StepCart.less';

const StepCart = () => {
	const cart = useSelector((state: RootState) => state.newOrder.cart);
	const restaurant = useSelector((state: RootState) => state.newOrder.restaurant);

	const totalPrice = cartService.getCartPrice(cart);

	return (
		<Col xs={24} xl={22} xxl={20} className='px-2'>
			<Row justify='center' className="step-cart-top-row">
				<Col xs={24} className='text-center'>
					<span className="step-cart-restaurant-name">{restaurant.name}</span>
				</Col>
				<Col xs={24} className='text-center'>
					<span className="step-cart-restaurant-address">
						{restaurant.address}, {restaurant.city}
					</span>
				</Col>
			</Row>
			<Row justify='center'>
				<Col xs={24} md={19}>
					{cart.cart_products.map((cartProduct, index) => (
						<ProductCard key={index} {...cartProduct} />
					))}
				</Col>
			</Row>
			<Row justify='center' className="step-cart-summary-row">
				<Col xs={24} md={19} className='text-center'>
					<span className="step-cart-summary">Całkowita wartość koszyka:
						<b className="step-cart-summary-price">
							{totalPrice.toFixed(2)}
						</b>
						PLN
					</span>
				</Col>
			</Row>
		</Col>
	);
};

export default StepCart;
