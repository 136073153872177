import { UserAddOutlined, LoginOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Default } from '../Responsive/Default';
import { Mobile } from '../Responsive/Mobile';
import CartMenuItem from './CartMenuItem';
import HomeMenuItem from './HomeMenuItem';

interface NotLoggedInNavbarProps {
	restaurantMenu: JSX.Element;
	promotions: JSX.Element;
	restaurants: JSX.Element;
}

const NotLoggedInNavbar = (props: NotLoggedInNavbarProps) => {
	const { promotions, restaurantMenu, restaurants } = props;
	const { t } = useTranslation('page');
	const location = useLocation();

	const signUpMenuItem = (isMobile: boolean) => (
		<Menu.Item key='/sign-up' icon={<UserAddOutlined />} className={isMobile ? '' : 'float-right'}>
			<Link to='/sign-up'>{t('Common.NavbarContainer.SignUp')}</Link>
		</Menu.Item>
	);

	const signInMenuItem = (isMobile: boolean) => (
		<Menu.Item key='/sign-in' icon={<LoginOutlined />} className={isMobile ? '' : 'float-right'}>
			<Link to='/sign-in'>{t('Common.NavbarContainer.SignIn')}</Link>
		</Menu.Item>
	);

	return (
		<>
			<Mobile>
				<Menu mode='horizontal' selectedKeys={[location.pathname]}>
					<HomeMenuItem />
					<Menu.SubMenu className='float-right' icon={<MenuOutlined className='mr-0' />}>
						{restaurantMenu}
						{promotions}
						{restaurants}
						{signUpMenuItem(true)}
						{signInMenuItem(true)}
						<CartMenuItem isMobile />
					</Menu.SubMenu>
				</Menu>
			</Mobile>

			<Default>
				<Menu mode='horizontal' selectedKeys={[location.pathname]}>
					<HomeMenuItem />
					{restaurantMenu}
					{promotions}
					{restaurants}
					{signUpMenuItem(false)}
					{signInMenuItem(false)}
					<CartMenuItem />
				</Menu>
			</Default>
		</>
	);
};

export default NotLoggedInNavbar;
