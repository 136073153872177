import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Card, Tag, Badge, Typography } from 'antd';
import { RootState } from 'App/state/root.reducer';
import { StatusType } from 'App/types/requestStatus';
import { getRestaurants } from 'App/state/restaurants/restaurants.thunk';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { RestaurantForGetAllRestaurantsResponse } from 'App/api/endpoints/restaurants/responses/getAllRestaurantsResponse';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { getAllUserPromotions } from 'App/state/promotions/promotions.thunk';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import { getPromotionIdsToShowInPromotionsPanel } from 'App/pages/PromotionsPage/utils/getPromotionIdsToShowInPromotionsPanel';
import './styles/RestaurantList.less';
import { getOpenRestaurantIds } from './utils/getOpenRestaurantIds';
import './RestaurantList.less';

const { LOADING } = StatusType;

const RestaurantList = () => {
	const dispatch = useDispatch();

	const restaurants = useSelector((state: RootState) => state.restaurants.restaurants);
	const restaurantsStatus = useSelector((state: RootState) => state.restaurants.status.getRestaurants);

	const promotions = useSelector((state: RootState) => state.promotions.promotions);
	const promotionsStatus = useSelector((state: RootState) => state.promotions.status.getAllUserPromotions);

	const localStorageKey = 'favouriteRestaurantId';
	const [currentFavouriteRestaurantId, setCurrentFavouriteRestaurantId] = useState(
		localStorage.getItem(localStorageKey)
	);
	const [loading, setLoading] = useState(true);
	const [openRestaurantIds, setOpenRestaurantIds] = useState<number[]>([]);
	const [restaurantIdsWithPromotion, setRestaurantIdsWithPromotion] = useState<number[]>([]);

	useEffect(() => {
		dispatch(getRestaurants());
		dispatch(getAllUserPromotions());
	}, [dispatch]);

	useEffect(() => {
		if (promotionsStatus === LOADING || restaurantsStatus === LOADING) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [promotionsStatus, restaurantsStatus]);

	useEffect(() => {
		if (restaurants) {
			setOpenRestaurantIds(getOpenRestaurantIds(restaurants));
		}
	}, [restaurants]);

	useEffect(() => {
		if (restaurants && promotions) {
			const restaurantIdsWithProm = getUniqueRestaurantIdsWithPromotionAvailable(restaurants, promotions);
			setRestaurantIdsWithPromotion(restaurantIdsWithProm);
		}
	}, [promotions, restaurants]);

	const toggleFavouriteRestaurant = (id: number) => {
		const currentLocalStorageValue = localStorage.getItem(localStorageKey);

		if (currentLocalStorageValue && currentLocalStorageValue === id.toString()) {
			localStorage.removeItem(localStorageKey);
			setCurrentFavouriteRestaurantId(null);
			return;
		}

		localStorage.setItem(localStorageKey, id.toString());
		setCurrentFavouriteRestaurantId(id.toString());
	};

	const getUniqueRestaurantIdsWithPromotionAvailable = (
		restaurants: RestaurantForGetAllRestaurantsResponse[],
		promotions: PromotionForGetAllUserPromotions[]
	) => {
		const restaurantIds: number[] = [];

		// id promek, które są aktywne i wpadamy w zakres dat UNTIL i FROM
		// BEZ SPRWDZENIA CZASÓW!
		const promotionIdsActiveAndDateRangeValid = getPromotionIdsToShowInPromotionsPanel(promotions);

		const checkedPromotions = promotions.filter((p) => promotionIdsActiveAndDateRangeValid.includes(p.id));

		if (checkedPromotions.length <= 0) {
			return [];
		}

		for (const promotion of checkedPromotions) {
			// sprwadzamy czy promka obejmująca wszystkie restauracje
			if (promotion.promotion_places.length <= 0) {
				// tak, zwracamy id wszystkich restauracji
				return restaurants.map((rest) => rest.id);
			}

			// promka nie obejmuje wszystkich restauracji:
			// iterujemy po miejscach i dodajemy je do arrayki
			for (const r of promotion.promotion_places) {
				const restaurant = r.place;

				// jeśli arrayka nie zawiera takiego id to go dodaj
				if (!restaurantIds.includes(restaurant.id)) {
					restaurantIds.push(restaurant.id);
				}
			}
		}

		return restaurantIds;
	};

	const renderRestaurantOpenStatus = (restaurant: RestaurantForGetAllRestaurantsResponse) => {
		if (openRestaurantIds.includes(restaurant.id)) {
			return <Tag color='green'>Otwarte</Tag>;
		}

		return <Tag color='red'>Zamknięte</Tag>;
	};

	const renderRestaurantPromotionStatus = (restaurant: RestaurantForGetAllRestaurantsResponse) => {
		// jeśli restauracja ma promocje oraz jest otwarta, to wyświetl, że są promocje
		if (openRestaurantIds.includes(restaurant.id) && restaurantIdsWithPromotion.includes(restaurant.id)) {
			return (
				<Tag className='ml-1' color='volcano'>
					Promocja
				</Tag>
			);
		}
		return <></>;
	};

	const renderRestaurantCard = (restaurant: RestaurantForGetAllRestaurantsResponse): JSX.Element => {
		return (
			<Card className='restaurant-list-card'>
				<Row gutter={16}>
					<Col xs={24}>
						<Row>
							<Col xs={24}>
								<Typography.Text strong>{restaurant.name}</Typography.Text>
							</Col>
							<Col xs={24}>
								<Typography.Text>{restaurant.address}</Typography.Text>
							</Col>
							<Col xs={24}>
								{renderRestaurantOpenStatus(restaurant)}
								{renderRestaurantPromotionStatus(restaurant)}
							</Col>
							<Col xs={24}>{restaurant.description}</Col>
						</Row>
					</Col>

					<Col xs={24} className='mt-4'>
						<Row gutter={16} justify='center'>
							<Col>
								<Link to={`/restaurant-menu/${restaurant.id}`}>
									<Button className='show-menu-button' type='primary'>
										Zobacz menu
									</Button>
								</Link>
							</Col>

							{restaurant.is_active_for_to_table_orders && (
								<Col>
									<Link to={`/restaurant-menu/${restaurant.id}`}>
										<Button className='show-menu-button' type='primary'>
											Zamów do stolika
										</Button>
									</Link>
								</Col>
							)}
						</Row>
					</Col>

				</Row>
			</Card>
		);
	};

	if (loading) {
		return <LoadingScreen container='screen' />;
	} else {
		return (
			<Row className='restaurant-list-top-row' justify='center'>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="restaurant-list-image-block" >
					<Row justify='center' align='middle' style={{height: '100%'}}>
						<span className="restaurant-list-image-block-text">Nasze restauracje</span>
					</Row>
				</Col>

				<Col xs={22} sm={22} md={12} lg={12} xl={12}>
					<>
						{restaurants &&
							restaurants.map((restaurant) => {
								return (
									<div className='mt-3' key={restaurant.id}>
										<Badge.Ribbon
											text={
												currentFavouriteRestaurantId === restaurant.id.toString() ? (
													<HeartFilled
														onClick={() => {
															toggleFavouriteRestaurant(restaurant.id);
														}}
													/>
												) : (
													<HeartOutlined
														onClick={() => {
															toggleFavouriteRestaurant(restaurant.id);
														}}
													/>
												)
											}
											className={
												currentFavouriteRestaurantId === restaurant.id.toString()
													? 'restaurant--container--card--ribbon_active'
													: 'restaurant--container--card--ribbon_inactive'
											}
										>
											{renderRestaurantCard(restaurant)}
										</Badge.Ribbon>
									</div>
								);
							})}
					</>
				</Col>
			</Row>
		);
	}
};

export default RestaurantList;
