import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';

export const getPromotionIdsToShowInPromotionsPanel = (promotions: PromotionForGetAllUserPromotions[]) => {
	const promotionIds: number[] = [];

	const now = new Date();

	for (const promotion of promotions) {
		if (!promotion.is_active) {
			continue;
		}

		// zakładamy, że z backendu dostajemy UTC
		const validFromLocale = new Date(promotion.date_from + 'Z');
		const validUntilLocale = new Date(promotion.date_until + 'Z');

		// teraz mamy obie daty w naszym lokalnym czasie
		if (now < validFromLocale || now > validUntilLocale) {
			continue;
		}

		// jeśli mieścimy się w ważności kuponu to dodajemy id do listy kuponow do wyswietlenia
		promotionIds.push(promotion.id);
	}

	return promotionIds;
};
