import { Card, Checkbox, Form, Radio, Typography } from 'antd';
import React from 'react';

interface ProductOptionProps {
	productOption: any; // opcje produktu
	restaurantProductOptions: any[]; // wszystkie opcje produktów dostępne w tej restauracji (stąd cena i info czy aktywny czy nie)
}

const ProductOption: React.FC<ProductOptionProps> = (props: ProductOptionProps) => {
	const { productOption, restaurantProductOptions } = props;

	const title = (
		<>
			{productOption.name} <Typography.Text type='secondary'>Max: {productOption.max_selections}</Typography.Text>
		</>
	);

	const isRequired = productOption.required_selections > 0;

	const isCheckbox = productOption.max_selections > 1 || productOption.required_selections < 1;

	// pobieramy aktualnie dostępne dodatki dla dania
	// respektując ustawienia poszczególnych restauracji
	const getAvailableProductOptionItems = () => {
		// jeśli produkt nie posiada żadnych opcji to nie ma co pokazać
		if (!productOption || productOption.option_items <= 0) {
			return [];
		}

		// jeśli produkt posiada opcje, ale restauracja nie ma żadnych dostępnych opcji, to nie ma co pokazać
		if (!restaurantProductOptions || restaurantProductOptions.length <= 0) {
			return [];
		}

		const allProductOptionItemIdsFromOriginal = productOption.option_items.flatMap((oi) => oi.id);

		// to są finalne opcje, które możemy wyświetlić
		const productOptionItemsFromRestaurant = restaurantProductOptions.filter((rpo) =>
			allProductOptionItemIdsFromOriginal.includes(rpo.option_item.id)
		);

		return productOptionItemsFromRestaurant;
	};

	const availableProductOptionItems = getAvailableProductOptionItems();

	return (
		<Card bordered={false} size='small' title={title} key={productOption.id}>
			<Form.Item
				name={productOption.id}
				rules={[{ required: isRequired }]}
				messageVariables={{ arg: productOption.name }}
			>
				{isCheckbox ? (
					<Checkbox.Group>
						{availableProductOptionItems.length > 0 &&
							availableProductOptionItems.map((optItem) => (
								<React.Fragment key={optItem.option_item.id}>
									<Checkbox disabled={!optItem.is_active} value={optItem.option_item.id}>{`${
										optItem.option_item.name
									} +${optItem.option_item.price_in_pln.toFixed(2)} PLN`}</Checkbox>
									<br />
								</React.Fragment>
							))}
					</Checkbox.Group>
				) : (
					<Radio.Group>
						{availableProductOptionItems.length > 0 &&
							availableProductOptionItems.map((optItem) => (
								<React.Fragment key={optItem.option_item.id}>
									<Radio disabled={!optItem.is_active} value={optItem.option_item.id}>{`${
										optItem.option_item.name
									} +${optItem.option_item.price_in_pln.toFixed(2)} PLN`}</Radio>
									<br />
								</React.Fragment>
							))}
					</Radio.Group>
				)}
			</Form.Item>
		</Card>
	);
};

export default ProductOption;
