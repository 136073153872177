import { Col, Divider, Image, Row, Typography } from 'antd';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import React, { useEffect, useState } from 'react';
import { getDiscountText } from '../utils/getDiscountText';
import { getPromotionImgUrl } from '../utils/getPromotionImgUrl';
import { getLocalDate } from '../utils/getLocalDate';
import { getValidProductIds } from '../utils/getValidProductIds';
import { getValidProductsText } from '../utils/getValidProductsText';
import { getValidRestaurantIds } from '../utils/getValidRestaurantIds';
import { getValidRestaurantsText } from '../utils/getValidRestaurantsText';

import './styles/PromotionDetails.less';

interface PromotionDetailsProps {
	promotion: PromotionForGetAllUserPromotions;
}
const PromotionDetails: React.FC<PromotionDetailsProps> = (props) => {
	const { promotion } = props;

	const [restautantIdsToShow, setRestautantIdsToShow] = useState<number[]>([]);
	const [productIdsToShow, setProductIdsToShow] = useState<number[]>([]);

	useEffect(() => {
		if (promotion) {
			setRestautantIdsToShow(getValidRestaurantIds(promotion));
			setProductIdsToShow(getValidProductIds(promotion));
		}
	}, [promotion]);

	const getDayAvailabilityText = (day) => {
		let fieldKey = '';

		switch (day) {
			case 'monday':
				fieldKey = 'monday';
				break;

			case 'tuesday':
				fieldKey = 'tuesday';
				break;

			case 'wednesday':
				fieldKey = 'wednsday';
				break;

			case 'thursday':
				fieldKey = 'thursday';
				break;

			case 'friday':
				fieldKey = 'friday';
				break;

			case 'saturday':
				fieldKey = 'saturday';
				break;

			case 'sunday':
				fieldKey = 'sunday';
				break;

			default:
				return;
		}

		if (!promotion.time_active.hasOwnProperty(fieldKey)) {
			return 'Cały dzień';
		}

		const timeStartInUtc = promotion.time_active[fieldKey].time_start;
		const timeEndInUtc = promotion.time_active[fieldKey].time_end;

		const dateStartLocal = new Date(`2010-01-01 ${timeStartInUtc}Z`);
		const dateEndLocal = new Date(`2010-01-01 ${timeEndInUtc}Z`);

		const timeStartLocal = dateStartLocal.toLocaleTimeString('pl-PL', {
			hour: '2-digit',
			minute: '2-digit',
		});

		const timeEndLocal = dateEndLocal.toLocaleTimeString('pl-PL', {
			hour: '2-digit',
			minute: '2-digit',
		});

		return `${timeStartLocal} - ${timeEndLocal}`;
	};

	if (promotion) {
		return (
			<div className='promotion-details'>
				<Row>
					<Col xs={24}>
						<div className='promotion-details__image'>
							<Image alt='img' src={getPromotionImgUrl(promotion)} />
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={18}>
						<Typography.Title level={4}>{promotion.title}</Typography.Title>
					</Col>
					<Col xs={6}>
						<Typography.Title className='promotion-details__discount-value' level={5}>
							{getDiscountText(promotion)}
						</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col>
						<Typography.Paragraph>{promotion.description}</Typography.Paragraph>
					</Col>
				</Row>

				<Divider />

				{promotion.minimal_cart_value_in_pln > 0 && (
					<>
						<Row>
							<Col>
								<Typography.Text strong>
									Minimalna wartość koszyka: {promotion.minimal_cart_value_in_pln.toFixed(2)} PLN
								</Typography.Text>
							</Col>
						</Row>
						<Divider />
					</>
				)}

				{promotion.minimal_cart_count > 0 && (
					<>
						<Row>
							<Col>
								<Typography.Text strong>
									Minimalny rozmiar koszyka: {promotion.minimal_cart_count} szt
								</Typography.Text>
							</Col>
						</Row>
						<Divider />
					</>
				)}

				<Row>
					<Col xs={24}>
						<Typography.Text strong>Dostępność promocji</Typography.Text>
						<br />
						<Typography.Text>
							{getLocalDate(promotion.date_from)} - {getLocalDate(promotion.date_until)}
						</Typography.Text>
						<br />
						<br />

						{/* dni */}
						<Typography.Text>Poniedziałek: {getDayAvailabilityText('monday')}</Typography.Text>
						<br />
						<Typography.Text>Wtorek: {getDayAvailabilityText('tuesday')}</Typography.Text>
						<br />
						<Typography.Text>Środa: {getDayAvailabilityText('wednesday')}</Typography.Text>
						<br />
						<Typography.Text>Czwartek: {getDayAvailabilityText('thursday')}</Typography.Text>
						<br />
						<Typography.Text>Piątek: {getDayAvailabilityText('friday')}</Typography.Text>
						<br />
						<Typography.Text>Sobota: {getDayAvailabilityText('saturday')}</Typography.Text>
						<br />
						<Typography.Text>Niedziela: {getDayAvailabilityText('sunday')}</Typography.Text>
						<br />
					</Col>
				</Row>

				<Divider />

				<Row>
					<Col>
						<Typography.Title level={5}>
							Objęte restauracje: {getValidRestaurantsText(promotion)}
						</Typography.Title>
						{restautantIdsToShow.length > 0 &&
							restautantIdsToShow.map((id) => {
								const restaurant = promotion.promotion_places.find((r) => r.place.id === id).place;

								return (
									<div key={id}>
										<Typography.Text>{restaurant.name}</Typography.Text>
										<br />
									</div>
								);
							})}
					</Col>
				</Row>

				<Divider />

				<Row>
					<Col>
						<Typography.Title level={5}>
							Objęte produkty: {getValidProductsText(promotion)}
						</Typography.Title>
						{productIdsToShow.length > 0 &&
							productIdsToShow.map((id) => {
								const product = promotion.promotion_products.find((p) => p.product.id === id).product;

								return (
									<div key={id}>
										<Typography.Text>
											{product.name} ({product.category_hierarchy})
										</Typography.Text>
										<br />
									</div>
								);
							})}
					</Col>
				</Row>
			</div>
		);
	} else {
		return <></>;
	}
};

export default PromotionDetails;
