import React, { ReactNode, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import EditProfileGeneralTab from './EditProfileGeneralTab';
import EditProfileAddressesTab from './EditProfileAddressesTab';
import './EditProfileTabs.less';

interface EditProfileTabsProps {
	accountDetails: GetAccountDetailsResponse;
}

export const EditProfileTabs: React.FC<EditProfileTabsProps> = ({ accountDetails }) => {
	interface AvailableTab {
		key: string;
		content: ReactNode;
		tab: string;
	}

	const availableTabs = [
		{
			key: 'k1',
			tab: 'Ogólne',
			content: <EditProfileGeneralTab accountDetails={accountDetails} />
		},
		{
			key: 'k2',
			tab: 'Adresy',
			content: <EditProfileAddressesTab />
		}
	] as AvailableTab[];

	const [state, setState] = useState<AvailableTab>(availableTabs.find((a) => a.key === 'k1'));

	const onTabChange = (key: string) => {
		setState(availableTabs.find((a) => a.key === key));
	};

	return (
		<Row
			style={{
				marginTop: 16,
			}}
			justify='center'
			className="edit-profile-tabls-card"
		>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Card
					tabList={availableTabs}
					activeTabKey={state.key}
					onTabChange={(key) => {
						onTabChange(key);
					}}
				>
					{state.content}
				</Card>
			</Col>
		</Row>
	);
};

export default EditProfileTabs;