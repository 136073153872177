import { MenuOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Default } from '../Responsive/Default';
import { Mobile } from '../Responsive/Mobile';
import AccountSubMenu from './AccountSubMenu';
import HomeMenuItem from './HomeMenuItem';
import CartMenuItem from './CartMenuItem';

interface ClientNavbarProps {
	accountDetails: GetAccountDetailsResponse;
	restaurantMenu: JSX.Element;
	promotions: JSX.Element;
	restaurants: JSX.Element;
	orders: JSX.Element;
}

const ClientNavbar: React.FC<ClientNavbarProps> = ({
	accountDetails,
	restaurantMenu,
	orders,
	promotions,
	restaurants,
}) => {
	const location = useLocation();

	return (
		<>
			<Mobile>
				<Menu mode='horizontal' selectedKeys={[location.pathname]}>
					<HomeMenuItem />
					<Menu.SubMenu className='float-right' icon={<MenuOutlined className='mr-0' />}>
						{restaurantMenu}
						{promotions}
						{restaurants}
						{orders}
						<Menu.Divider />
						<CartMenuItem isMobile />
						<AccountSubMenu isMobile={true} accountDetails={accountDetails} />
					</Menu.SubMenu>
				</Menu>
			</Mobile>

			<Default>
				<Menu mode='horizontal' selectedKeys={[location.pathname]}>
					<HomeMenuItem />
					{restaurantMenu}
					{promotions}
					{restaurants}
					{orders}
					<AccountSubMenu isMobile={false} accountDetails={accountDetails} />
					<CartMenuItem />
				</Menu>
			</Default>
		</>
	);
};

export default ClientNavbar;
