import { Button, Col, Row, Typography } from 'antd';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import React from 'react';
import { Link } from 'react-router-dom';

interface EditProfileGeneralTabsProps {
	accountDetails: GetAccountDetailsResponse;
}

const EditProfileGeneralTab: React.FC<EditProfileGeneralTabsProps> = ({ accountDetails }) => {

	if (accountDetails) {
		return (
			<>
				<Row gutter={[16, 16]}>

					<Col span={24}>

						<Typography>
							<Typography.Text type='secondary'>Email: </Typography.Text>
							<Typography.Text>{accountDetails.email}</Typography.Text>
						</Typography>

						<Typography>
							<Typography.Text type='secondary'>Imię: </Typography.Text>
							<Typography.Text>{accountDetails.client_information.name}</Typography.Text>
						</Typography>

                        <Typography>
							<Typography.Text type='secondary'>Nazwisko: </Typography.Text>
							<Typography.Text>{accountDetails.client_information.last_name}</Typography.Text>
						</Typography>

						<Typography>
							<Typography.Text type='secondary'>Telefon: </Typography.Text>
							<Typography.Text>{accountDetails.client_information.phone_number}</Typography.Text>
						</Typography>

					</Col>
				</Row>
				<Row justify='center'>
					<Link to={'/account/edit'} >
                            <Button danger className='edit-profile-button'>Edytuj profil</Button>
                    </Link>
				</Row>
			</>
		);
	} else {
		return <></>;
	}
};

export default EditProfileGeneralTab;
