import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RestaurantForGetAllRestaurantsResponse } from 'App/api/endpoints/restaurants/responses/getAllRestaurantsResponse';
import { GetRestaurantResponse } from 'App/api/endpoints/restaurants/responses/getRestaurantResponse';
import StatusType from 'App/types/requestStatus';
import { restaurantsInitialState, RestaurantsState } from './restaurants.state';

const { FAILED, SUCCESS, LOADING } = StatusType;

const restaurantsSlice = createSlice({
	name: 'restaurants',
	initialState: restaurantsInitialState,
	reducers: {
		getRestaurantsStart: (state: RestaurantsState) => {
			state.status.getRestaurants = LOADING;
			state.restaurants = null;
		},
		getRestaurantsSuccess: (
			state: RestaurantsState,
			action: PayloadAction<RestaurantForGetAllRestaurantsResponse[]>
		) => {
			state.status.getRestaurants = SUCCESS;
			state.restaurants = action.payload;
		},
		getRestaurantsFailure: (state: RestaurantsState) => {
			state.status.getRestaurants = FAILED;
		},

		getRestaurantStart: (state: RestaurantsState) => {
			state.status.getRestaurant = LOADING;
			state.restaurant = null;
		},
		getRestaurantSuccess: (state: RestaurantsState, action: PayloadAction<GetRestaurantResponse>) => {
			state.status.getRestaurant = SUCCESS;
			state.restaurant = action.payload;
		},
		getRestaurantFailure: (state: RestaurantsState) => {
			state.status.getRestaurant = FAILED;
		},
	},
});

export default restaurantsSlice;

export const {
	getRestaurantsStart,
	getRestaurantsSuccess,
	getRestaurantsFailure,

	getRestaurantStart,
	getRestaurantSuccess,
	getRestaurantFailure,
} = restaurantsSlice.actions;
