import { OrderStatusEnum } from "App/domain/enums/orderStatusEnum";

export const orderStatusMapper = (source: string) => {
    switch (source) {
        case 'FINISHED':
            return OrderStatusEnum.finished;
        case 'ERROR':
            return OrderStatusEnum.error;
        case 'IN_PREPARE':
            return OrderStatusEnum.in_preparation;
        case 'NEW':
            return OrderStatusEnum.new_order;
        case 'CANCEL':
            return OrderStatusEnum.cancel;
        case 'SEND':
            return OrderStatusEnum.in_transport;
        case 'PAID':
            return OrderStatusEnum.paid;
        case 'READY':
            return OrderStatusEnum.ready;
        default:
            return
    }
}