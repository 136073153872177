import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { default as ConfirmEmail } from './confirmEmail/ConfirmEmailContainer';
import { default as EditProfile } from './editProfile/EditProfileContainer';
import { default as ForgotPassword } from './forgotPassword/ForgotPasswordContainer';
import { default as EditProfileForm } from './editProfile/EditProfileFormContainer';
import { default as AddAddress } from './editProfile/AddUserAddressContainer';
import { default as UpdateAddress } from './editProfile/UpdateUserAddressContainer';
import { default as ChangePassword } from './changePassword/ChangePasswordContainer';

import ProtectedRoute from 'App/common/components/ProtectedRoute';

import Role from 'App/types/role';

const AccountPageContainer: React.FC<{}> = () => {
	return (
		<Switch>
			<Route path='/account/confirm-email/:activationCode' component={ConfirmEmail} />
			<Route exact path='/account/forgot-password' component={ForgotPassword} />

			<ProtectedRoute
				exact
				path='/account'
				component={EditProfile}
				acceptedRoles={[Role.ADMIN, Role.USER, Role.CLIENT]}
			/>

			<ProtectedRoute
				exact
				path='/account/change-password'
				component={ChangePassword}
				acceptedRoles={[Role.ADMIN, Role.USER, Role.CLIENT]}
			/>

			<ProtectedRoute
				exact
				path='/account/edit'
				component={EditProfileForm}
				acceptedRoles={[Role.ADMIN, Role.USER, Role.CLIENT]}
			/>
			<ProtectedRoute
				exact
				path='/account/add-address'
				component={AddAddress}
				acceptedRoles={[Role.ADMIN, Role.USER, Role.CLIENT]}
			/>
			<ProtectedRoute
				exact
				path='/account/update-address/:userAddressId'
				component={UpdateAddress}
				acceptedRoles={[Role.ADMIN, Role.USER, Role.CLIENT]}
			/>
		</Switch>
	);
};

export default AccountPageContainer;
