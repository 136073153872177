const storageTimeInMinutes = 20;

export const localStorageHelper = {
	keys: {
		cart: 'thali_new_order_cart',
		cartDate: 'thali_new_order_cart_date',
		restaurant: 'thali_new_order_restaurant',
		restaurantDate: 'thali_new_order_restaurant_date',
	},

	getRestaurant() {
		let restaurant = JSON.parse(localStorage.getItem(this.keys.restaurant));

		if (!restaurant) return null;

		let restaurantDate = new Date(localStorage.getItem(this.keys.restaurantDate));

		let now = new Date();
		const diffInMs = now.getMilliseconds() - restaurantDate.getMilliseconds();
		const diffInMinutes = diffInMs / 1000 / 60;

		if (diffInMinutes > storageTimeInMinutes) {
			this.clear();
			return null;
		}

		return restaurant;
	},

	getCart() {
		let cart = JSON.parse(localStorage.getItem(this.keys.cart));

		if (!cart) return null;

		let cartDate = new Date(localStorage.getItem(this.keys.cartDate));

		let now = new Date();
		const diffInMs = now.getMilliseconds() - cartDate.getMilliseconds();
		const diffInMinutes = diffInMs / 1000 / 60;

		if (diffInMinutes > storageTimeInMinutes) {
			this.clear();
			return null;
		}

		return cart;
	},

	clear() {
		localStorage.removeItem(this.keys.cart);
		localStorage.removeItem(this.keys.cartDate);
		localStorage.removeItem(this.keys.restaurant);
		localStorage.removeItem(this.keys.restaurantDate);
	},
};
