import { CheckCircleOutlined, ClockCircleOutlined, FireFilled, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Empty, Form, Image, InputNumber, notification, Row, Tooltip, Typography } from 'antd';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import { isRestaurantOpen } from 'App/pages/RestaurantsPage/restaurantsList/utils/isRestaurantOpen';
import { cartProductService } from 'App/services/cartProductService';
import { updateCart, updateRestaurant } from 'App/state/newOrder/newOrder.slice';
import { newOrder_cartInitialState } from 'App/state/newOrder/newOrder.state';
import { RootState } from 'App/state/root.reducer';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductImgUrl } from '../utils/getProductImgUrl';
import ProductOption from './components/productOptions/ProductOption';
import ProductPromotionsSection from './components/productPromotionsSection/ProductPromotionsSection';
import './styles/DetailedMenuProduct.less';

interface DetailedMenuProductProps {
	product: any;
	restaurant: any;
	validPromotionsForProduct: PromotionForGetAllUserPromotions[];
	closeModal: () => void;
}

const DetailedMenuProduct: React.FC<DetailedMenuProductProps> = (props: DetailedMenuProductProps) => {
	const dispatch = useDispatch();
	const restaurantFromOrder = useSelector((state: RootState) => state.newOrder.restaurant);
	const cart = useSelector((state: RootState) => state.newOrder.cart);

	const { product, restaurant, validPromotionsForProduct, closeModal } = props;
	const [finalPrice, setFinalPrice] = useState(product?.price_pln);

	const addProductToCart = (values: any) => {
		//sprawdź czy w state jest restauracja
		if (!restaurantFromOrder) {
			//jak nie ma to dodaj do state i dodaj cartProduct
			dispatch(
				updateRestaurant({
					...restaurant,
					products: [],
					place_products: [],
					place_product_options: [],
				})
			);

			let cartProduct = cartProductService.getCartProductFromForm(values, product);
			cartProduct.promotions = validPromotionsForProduct;
			dispatch(updateCart({ ...newOrder_cartInitialState, cart_products: [cartProduct] }));
		} else {
			//jak jest restauracja
			//to sprawdz czy to ta sama z ktorej jest dodawany ten cartProduct
			if (restaurantFromOrder.id !== restaurant.id) {
				//jak nie to wyrzuć błąd że nie można z dwóch restauracji na raz
				notification.warning({
					message: 'Uwaga',
					description: 'Do jednego koszyka nie można dodać produktów z różnych restauracji!',
				});
			} else {
				//jak jest jest ta sama restauracja
				//to sprawdz czy to ten sam produkt czy nie i albo dodaj nowy albo dodaj ammount
				let cartProduct = cartProductService.getCartProductFromForm(values, product);
				let cartProductIndex = cart.cart_products.findIndex(
					(product) => JSON.stringify(product) === JSON.stringify(cartProduct)
				);

				//jak nie ma to znaczy ze nowy i dodajemy do arrayki
				if (cartProductIndex === -1) {
					cartProduct.promotions = validPromotionsForProduct;
					dispatch(updateCart({ ...cart, cart_products: [...cart.cart_products, cartProduct] }));
				} else {
					//jak produkt już jest w koszyczku
					//to ustalamy nową ilość
					const newCount = cart.cart_products[cartProductIndex].count + cartProduct.count;

					//i tworzymy nową arraykę z zaaktualizowaną ilością
					const newCartProducts = cart.cart_products.map((product, index) =>
						index === cartProductIndex
							? { ...cart.cart_products[cartProductIndex], count: newCount }
							: product
					);

					dispatch(updateCart({ ...cart, cart_products: newCartProducts }));
				}
			}
		}
		closeModal();
	};

	const onFieldsChange = (changedFields, allFields) => {
		let tempFinalPrice = cartProductService.getFinalPriceFromForm(allFields, product);
		setFinalPrice(tempFinalPrice);
	};

	if (product) {
		return (
			<>
				<Row>
					<Col xs={24}>
						<Image preview={false} src={getProductImgUrl(product)} placeholder />
					</Col>
					<Col xs={24}>
						<Row justify='space-between'>
							<Col>
								{product.is_vegan && (
									<span className='menu__attribute'>
										<Tooltip title='Vegan'>
											<CheckCircleOutlined className='menu__vegan' />
										</Tooltip>
									</span>
								)}
								{product.is_long_to_prepare && (
									<span className='menu__attribute'>
										<Tooltip title='Potrawa długa w przygotowaniu'>
											<ClockCircleOutlined className='menu__long-to-prepare' />
										</Tooltip>
									</span>
								)}
								{product.is_spicy1 && (
									<span className='menu__attribute'>
										<Tooltip title='Ostry'>
											<FireFilled className='menu__spicy1' />
										</Tooltip>
									</span>
								)}
								{product.is_spicy2 && (
									<span className='menu__attribute'>
										<Tooltip title='Ostrzejszy'>
											<FireFilled className='menu__spicy2' />
										</Tooltip>
									</span>
								)}
								{product.is_spicy3 && (
									<span className='menu__attribute'>
										<Tooltip title='Najostrzejszy'>
											<FireFilled className='menu__spicy3' />
										</Tooltip>
									</span>
								)}
							</Col>
							<Col>
								<Button type='primary' className='detailed-menu__price'>{`${product.price_pln.toFixed(
									2
								)} PLN`}</Button>
							</Col>
						</Row>
						<Row>
							<Col xs={24}>
								<div className='py-2'>
									<Typography.Text>{product.description}</Typography.Text>
								</div>
								<Divider className='mx-0 mt-0 mb-2' />
							</Col>
							{product.product_options && (
								<Col xs={24}>
									<Form onFinish={addProductToCart} onFieldsChange={onFieldsChange}>
										{product.product_options.map((productOption, index) => (
											<ProductOption
												key={index}
												productOption={productOption}
												restaurantProductOptions={restaurant.place_product_options}
											/>
										))}

										{validPromotionsForProduct.length > 0 && (
											<ProductPromotionsSection promotionProducts={validPromotionsForProduct} />
										)}

										<Row justify='space-between' align='middle'>

											<Col 
											xs={24} sm={24} md={24} lg={24} xl={24}
											>
												<Form.Item
													rules={[{ required: true }]}
													name='quantity'
													label='Ilość'
													messageVariables={{ arg: 'Ilość' }}
													initialValue={1}
												>
													<InputNumber step={1} min={1} max={255} className='w-20' />
												</Form.Item>
											</Col>

											<Col 
											xs={24} sm={24} md={24} lg={24} xl={24}
											>
												<Form.Item>
													<Typography.Text type='secondary'>
														Suma: {finalPrice.toFixed(2)} PLN
													</Typography.Text>
												</Form.Item>
											</Col>

											<Col 
											xs={24} sm={24} md={24} lg={24} xl={24}
											className='text-center'
											>
												<Form.Item>
													{isRestaurantOpen(restaurant) ? (
														<Button
															htmlType='submit'
															type='primary'
															icon={<ShoppingCartOutlined />}
														>
															Dodaj do koszyka
														</Button>
													) : (
														<Tooltip title='Restauracja jest zamknięta'>
															<Button
																htmlType='submit'
																type='primary'
																icon={<ShoppingCartOutlined />}
																disabled
															>
																Dodaj do koszyka
															</Button>
														</Tooltip>
													)}
												</Form.Item>
											</Col>


										</Row>
									</Form>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
			</>
		);
	} else {
		return (
			<Empty
				description={'Brak produktu'}
				image={require('../../../common/assets/empty-placeholder-bw.jpg')}
				imageStyle={{
					height: 60,
				}}
			/>
		);
	}
};

export default DetailedMenuProduct;
