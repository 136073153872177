import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';

export const getValidRestaurantIds = (promotion: PromotionForGetAllUserPromotions) => {
	const restaurantIds: number[] = [];

	for (const restaurant of promotion.promotion_places) {
		restaurantIds.push(restaurant.place.id);
	}

	return restaurantIds;
};
