import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import { Button, Col, notification, Row } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { cleanUpAccountStatusStart } from 'App/state/account/account.slice';
import { confirmEmail } from 'App/state/account/account.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Typography } from 'antd';

import './ConfirmEmailContainer.less'
import { Link } from 'react-router-dom';

const { Title } = Typography;
interface RouteParams {
    activationCode: string;
}

interface ConfirmEmailContainerProps extends RouteComponentProps<RouteParams> { }

const ConfirmEmailContainer: React.FC<ConfirmEmailContainerProps> = ({ match }: ConfirmEmailContainerProps) => {
    const dispatch = useDispatch();
    const status = useSelector((state: RootState) => state.account.status.confirmEmail);
    const { t } = useTranslation('page');

    useEffect(() => {
        if (status === StatusType.INITIAL) {
            let handleSuccess: () => void = () => {
                notification.success({
                    message: t('common:Success.Success'),
                    description: t('AccountPage.ConfirmEmailContainer.ConfirmSuccess')
                })
            };

            const { activationCode } = match.params;
            dispatch(confirmEmail(activationCode, handleSuccess))
        }
        return () => {
            cleanUpAccountStatusStart();
        }
    })

    return status === StatusType.LOADING || status === StatusType.INITIAL ?
        (
            <LoadingScreen container="screen" />
        )
        :
        (
            <>
                {status === StatusType.SUCCESS &&
                    <Col className="confirmEmail">
                        <Row justify="center" align="middle">
                            <CheckOutlined className="confirmIconSuccess" />
                        </Row>
                        <Row justify="center" align="middle">
                            <Title className="text-center" level={4}>{t('AccountPage.ConfirmEmailContainer.EmailConfirmed')}</Title>
                        </Row>
                        <Row justify="center" align="middle">
                            <Link to="/sign-in">
                                <Button size="large" block type="primary">{t('AccountPage.ConfirmEmailContainer.Login')}</Button>
                            </Link>
                        </Row>
                    </Col>}
                {status === StatusType.FAILED &&
                    <Col className="confirmEmail">
                        <Row justify="center" align="middle">
                            <ExclamationOutlined className="confirmIconError" />
                        </Row>
                        <Row justify="center" align="middle">
                            <Title className="text-center" level={4}>{t('AccountPage.ConfirmEmailContainer.ConfirmError')}</Title>
                        </Row>
                    </Col>}
            </>
        )
}

export default ConfirmEmailContainer;
