import appConfig from 'app.config';
import { requests } from '../../agent/agent';
import { LoginRequest, RegisterRequest } from './requests';
import { RegisterResponse } from './responses';

const { baseUrlToIncludeInEmail, confirmEmail } = appConfig.urls.frontend;

export const AuthApi = {
	login: (body: LoginRequest): Promise<{ data: any }> => {
		const qs = require('querystring');

		const requestBody = {
			username: body.email,
			password: body.password,
			grant_type: 'password',
			client_id: 'mobile_client',
			client_secret: 'mobile_client',
		};

		return requests.post(`oauth/token`, qs.stringify(requestBody));
	},

	logout: (): Promise<any> => requests.post(`/auth/logout`, {}),

	register: (body: RegisterRequest): Promise<RegisterResponse> => {
		return requests.post(`/register`, {
			...body,
			urlToIncludeInEmail: `${baseUrlToIncludeInEmail}${confirmEmail}`,
		});
	},

	refreshToken: (): Promise<{ data: any }> => {
		const qs = require('querystring');

		const requestBody = {
			refresh_token: localStorage.getItem('refresh_token') || '',
			grant_type: 'refresh_token',
			client_id: 'mobile_client',
			client_secret: 'mobile_client',
		};

		return requests.post(`oauth/token`, qs.stringify(requestBody));
	},
};
