import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Col, Row, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { addUserAddress, getAccountDetails } from 'App/state/account/account.thunk';
import { AddUserAddressRequest } from 'App/api/endpoints/account/requests';
import AddUserAddressForm from './components/addUserAddressForm/AddUserAddressForm';
import './AddUserAddressContainer.less';


const AddUserAddressContainer: React.FC<{}> = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { t } = useTranslation(['page', 'common']);

	const onFinish = (values: AddUserAddressRequest) => {
		const onSuccess = () => {
			dispatch(getAccountDetails());
			history.push('/account');

			notification.success({
				message: t('common:Success.Success'),
				description: 'Dodano nowy adres',
			});
		};

		dispatch(addUserAddress(values, onSuccess));
	};

	return (
		<React.Fragment>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} className='add-user-address-container-image-block'>
				<Row justify='center' align='middle' style={{ height: '100%' }}>
					<span className='add-user-address-container-image-block-text'>Dodaj adres</span>
				</Row>
			</Col>
			<Row align='middle' justify='center'>
				<Col span={24}>
					<AddUserAddressForm
						onFinish={onFinish}
						userLoggedIn={true}
					/>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default AddUserAddressContainer;
