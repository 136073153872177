import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import { getValidProductIds } from './getValidProductIds';

export const getValidProductsText = (promotion: PromotionForGetAllUserPromotions) => {
	const count = getValidProductIds(promotion).length;

	if (count > 0) {
		return count;
	}

	return 'Wszystkie';
};
