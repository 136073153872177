import { StatusType } from 'App/types/requestStatus';

const { INITIAL } = StatusType;

export interface AuthState {
	status: {
		login: StatusType;
		logout: StatusType;
		register: StatusType;
		refreshToken: StatusType;
	};
	tokens: {
		token: string;
		refreshToken: string;
	};
	flags: {
		appStarting: boolean;
		login: boolean;
	}
}

export const authInitialState: AuthState = {
	status: {
		login: INITIAL,
		logout: INITIAL,
		register: INITIAL,
		refreshToken: INITIAL,
	},
	tokens: {
		token: null,
		refreshToken: null,
	},
	flags: {
		appStarting: true,
		login: false,
	}
};
