import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import { getValidRestaurantIds } from './getValidRestaurantIds';

export const getValidRestaurantsText = (promotion: PromotionForGetAllUserPromotions) => {
	const count = getValidRestaurantIds(promotion).length;

	if (count > 0) {
		return count;
	}

	return 'Wszystkie';
};
