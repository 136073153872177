import { RestaurantForGetAllRestaurantsResponse } from 'App/api/endpoints/restaurants/responses/getAllRestaurantsResponse';
import { isRestaurantOpen } from './isRestaurantOpen';

export const getOpenRestaurantIds = (restaurants: RestaurantForGetAllRestaurantsResponse[]) => {
	const openRestaurantIds: number[] = [];

	if (restaurants.length <= 0) {
		return [];
	}

	for (const restaurant of restaurants) {
		if (isRestaurantOpen(restaurant)) {
			openRestaurantIds.push(restaurant.id);
		}
	}

	return openRestaurantIds;
};
