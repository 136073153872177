import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

import { AccountApi } from '../endpoints/account/accountApi';
import { AuthApi } from '../endpoints/auth/authApi';
import { baseURL } from './axios/configuration';
import { RestaurantsApi } from '../endpoints/restaurants/restaurantsApi';
import { ProductCategoriesApi } from '../endpoints/productCategories/productCategoriesApi';
import { UserOrdersApi } from '../endpoints/userOrders/userOrdersApi';
import { UserPromotionsApi } from '../endpoints/userPromotions/userPromotionsApi';
import { GeolocationApi } from '../endpoints/geolocation/geolocationApi';

const responseBodyAxios = (response: AxiosResponse) => {
	if (response?.data && 'data' in response.data && Object.keys(response.data).length === 1) {
		return response.data.data;
	}

	return response.data;
};

const responseBodyFetch = (response: Response) => response.json();

const defaultHeaders = {
	Accept: 'application/x-www-form-urlencoded',
};

export const requests = {
	get: (url: string, params?: {}) =>
		axios
			.get(url, {
				params,
				headers: {},
			})
			.then(responseBodyAxios),
	post: (url: string, body: {}, config?: AxiosRequestConfig | undefined) =>
		axios.post(url, body, config).then(responseBodyAxios),

	put: (url: string, body: {}, config?: AxiosRequestConfig | undefined) =>
		axios.put(url, body, config).then(responseBodyAxios),
	//delete: (url: string) => axios.delete(url).then(responseBodyAxios),
	delete: (url: string) => axios.delete(url),

	fetch: (url: string, config?: RequestInit) =>
		fetch(`${baseURL}${url}`, { ...config, headers: config.headers || defaultHeaders }).then((response) => {
			if (response && response.status !== 200) throw response;
			return responseBodyFetch(response);
		}),
	download: (url: string, fileName: string) =>
		axios({
			url: url,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}),
};

export default {
	Account: AccountApi,
	Auth: AuthApi,
	Restaurants: RestaurantsApi,
	ProductCategories: ProductCategoriesApi,
	UserOrders: UserOrdersApi,
	UserPromotions: UserPromotionsApi,
	Geolocation: GeolocationApi,
};
