import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Image, Carousel, Card, Select, Rate, Modal } from 'antd';
import './HomePageContainer.less';

import tmpImage1 from './assets/about-us-1.jpg';
import tmpImage2 from './assets/about-us-2.jpg';
import tmpImage3 from './assets/about-us-3.jpg';
import leafImage from './assets/leaf.png';
import menuCardImage1 from './assets/menu-card-1.jpg';
import menuCardImage2 from './assets/menu-card-2.jpg';
import menuCardImage3 from './assets/menu-card-3.jpg';
import menuDishImage from './assets/menu-dish.png';
import imageGalleryImage1 from './assets/image-gallery-1.jpg';
import imageGalleryImage2 from './assets/image-gallery-2.jpg';
import imageGalleryImage3 from './assets/image-gallery-3.jpg';
import imageGalleryImage4 from './assets/image-gallery-4.jpg';
import imageGalleryImage5 from './assets/image-gallery-5.jpg';
import imageGalleryImage6 from './assets/image-gallery-6.jpg';
import appLogoBigImage from './assets/appLogoBig.png';
import facebookLogoImage from './assets/facebookLogo.png';
import messengerLogoImage from './assets/messengerLogo.png';

import { DownCircleOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import Link from 'antd/lib/typography/Link';
import { useHistory } from 'react-router';

const { Option } = Select;

const HomePageContainer: React.FC<{}> = () => {
	const history = useHistory();
	const [chosenRestaurant, setChosenRestaurant] = useState(0);

	const [rulebookModal, setRulebookModalVisible] = useState(false);

	const [privacyPolicyModal, setPrivacyPolicyModalVisible] = useState(false);

	const [marketingConsentsModal, marketingConsentsModalVisible] = useState(false);

	const aboutUsPageRef = useRef(null);
	const handleAboutUsScrollOnClick = () =>
		aboutUsPageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

	const landingPageRef = useRef(null);
	const handleLandingPageScrollOnClick = () =>
		landingPageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

	const menuPageRef = useRef(null);
	const handleMenuScrollOnClick = () => menuPageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

	const reviewsPageRef = useRef(null);
	const handleReviewsScrollOnClick = () =>
		reviewsPageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

	const contactPageRef = useRef(null);
	const handleContactScrollOnClick = () =>
		contactPageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

	const firstSelectRestaurantRef = useRef(null);
	const secondSelectRestaurantRef = useRef(null);

	const handleRestaurantSelectClick = (restaurantId: number) => {
		setChosenRestaurant(restaurantId);
	};

	useEffect(() => {
		document
			.getElementsByClassName('ant-layout-header fixed-header bg-site')[0]
			.classList.add('top-navbar-custom-header');

		window.addEventListener('scroll', listenScrollEvent);
	}, [chosenRestaurant]);

	const listenScrollEvent = (e) => {
		if (window.scrollY > 700) {
			console.log('scroll');
		} else {
		}
	};

	const restaurantsData = [
		{
			id: 0,
			name: 'Thali na Jedności',
			email: 'jednosci@thali.pl',
			phone: '727 870 333',
			addressStreet: 'Jedności Narodowej 161',
			addressCity: '50-303 Wrocław',
			openingHours: {
				monday: '11:00 - 21:00',
				tuesday: '11:00 - 21:00',
				wednesday: '11:00 - 21:00',
				thursday: '11:00 - 21:00',
				friday: '11:00 - 21:00',
				saturday: '11:00 - 21:00',
				sunday: '11:00 - 21:00',
			},
		},
		{
			id: 1,
			name: 'Thali na Skłodowskiej',
			email: 'sklodowskiej@thali.pl',
			phone: '722 765 555',
			addressStreet: 'Curie-Skłodowskiej 5',
			addressCity: '50-381 Wrocław',
			openingHours: {
				monday: '12:00 - 21:30',
				tuesday: '12:00 - 21:30',
				wednesday: '12:00 - 21:30',
				thursday: '12:00 - 21:30',
				friday: '12:00 - 21:30',
				saturday: '12:00 - 21:30',
				sunday: '12:00 - 21:30',
			},
		},
		{
			id: 2,
			name: 'Thali na Ruskiej',
			email: 'ruska@thali.pl',
			phone: '727 817 771',
			addressStreet: 'Ruska 19',
			addressCity: '50-079 Wrocław',
			openingHours: {
				monday: '12:00 - 21:00',
				tuesday: '12:00 - 22:00',
				wednesday: '12:00 - 22:00',
				thursday: '12:00 - 22:00',
				friday: '12:00 - 22:00',
				saturday: '12:00 - 22:00',
				sunday: '12:00 - 21:00',
			},
		},
		{
			id: 3,
			name: 'Thali Poznań',
			email: 'thalipalace9@gmail.com',
			phone: '722 711 111',
			addressStreet: 'Ratajczaka 30',
			addressCity: '60-101 Poznań',
			openingHours: {
				monday: '12:00 - 22:00',
				tuesday: '12:00 - 22:00',
				wednesday: '12:00 - 22:00',
				thursday: '12:00 - 22:00',
				friday: '12:00 - 22:00',
				saturday: '12:00 - 22:00',
				sunday: '12:00 - 22:00',
			},
		},
		{
			id: 4,
			name: 'Thali Express Kiełczów',
			email: 'biuro@thali.pl',
			phone: '515 550 898',
			addressStreet: 'Wrocławska 2',
			addressCity: '55-093 Kiełczów',
			openingHours: {
				monday: '12:00 - 21:00',
				tuesday: '12:00 - 21:00',
				wednesday: '12:00 - 21:00',
				thursday: '12:00 - 21:00',
				friday: '12:00 - 21:00',
				saturday: '12:00 - 21:00',
				sunday: '12:00 - 19:00',
			},
		},
		{
			id: 5,
			name: 'Thali Food Truck',
			email: 'biuro@thali.pl',
			phone: '727805556',
			addressStreet: 'Ferdynanda Magellana',
			addressCity: '51-505 Wrocław',
			openingHours: {
				monday: '12:00 - 20:00',
				tuesday: '12:00 - 20:00',
				wednesday: '12:00 - 20:00',
				thursday: '12:00 - 20:00',
				friday: '12:00 - 20:00',
				saturday: '12:00 - 20:00',
				sunday: '12:00 - 20:00',
			},
		},
		{
			id: 6,
			name: 'Thali Express Stabłowice',
			email: 'biuro@thali.pl',
			phone: '727 805 552',
			addressStreet: 'ul. Wojanowska 42',
			addressCity: '54-06 Wrocław',
			openingHours: {
				monday: '09:00 - 20:00',
				tuesday: '09:00 - 20:00',
				wednesday: '09:00 - 20:00',
				thursday: '09:00 - 20:00',
				friday: '09:00 - 20:00',
				saturday: '09:00 - 20:00',
				sunday: '09:00 - 20:00',
			},
		},
	];

	const showMenuRestaurantHandler = () => {
		history.push(`/restaurant-menu/${chosenRestaurant + 1}`);
	};

	const showRestaurantsHandler = () => {
		history.push(`/restaurants`);
	};

	const signInHandler = () => {
		history.push(`/sign-in`);
	};

	const signUpHandler = () => {
		history.push(`/sign-up`);
	};

	const forgotPasswordHandler = () => {
		history.push(`/account/forgot-password`);
	};

	const promotionsHandler = () => {
		history.push(`/promotions`);
	};

	const accountHandler = () => {
		history.push(`/account`);
	};

	return (
		<div>
			{/* Ekran startowy */}
			<Row justify='start'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} className='home-container-landing-image-block'>
					<Row justify='center'>
						<Col xs={22} sm={20} md={20} lg={16} xl={16}>
							<div className='home-container-landing-text-block'>
								<div className='home-container-landing-text-header'>Odkryj wyjątkowe smaki</div>
								<div className='home-container-landing-divider'></div>
								<div className='home-container-landing-text-description'>
									Nasza kuchnia to świeżość, zdrowie i smak, przygotowane i serwowane z największą
									pieczołowitością i starannością przez wyspecjalizowaną załogę.
								</div>
								<div className='home-container-landing-button-block'>
									<Select
										defaultValue={0}
										className='home-container-landing-select-restaurant-dropdown'
										onChange={(value, event) => handleRestaurantSelectClick(value)}
										ref={firstSelectRestaurantRef}
										value={chosenRestaurant}
									>
										<Option value={2}>Thali na Ruskiej</Option>
										<Option value={1}>Thali na Skłodowskiej</Option>
										<Option value={0}>Thali na Jedności</Option>
										<Option value={3}>Thali Poznań</Option>
										<Option value={4}>Thali Express Kiełczów</Option>
										<Option value={6}>Thali Express Stabłowice</Option>
										<Option value={5}>Thali Food Truck</Option>
									</Select>
									<br></br>
									<Button
										onClick={showMenuRestaurantHandler}
										className='home-container-landing-button'
									>
										Zamów online
									</Button>
								</div>
							</div>
						</Col>
					</Row>

					<Row justify='center'>
						<DownCircleOutlined
							onClick={handleAboutUsScrollOnClick}
							className='home-container-landing-down-button'
						/>
					</Row>
				</Col>
			</Row>

			{/* Sekcja o nas */}
			<Row justify='center' ref={aboutUsPageRef}>
				<Col xs={22} sm={20} md={22} lg={18} xl={18}>
					<div className='home-container-about-us-block'>
						<Row justify='end' style={{ height: 0 }}>
							<img src={leafImage} alt="About us" className='home-container-about-us-leaf' />
						</Row>

						<Row>
							<Col xs={24} sm={24} md={24} lg={8} xl={8}>
								<Carousel autoplay>
									<div>
										<img src={tmpImage1} alt="About us 1" className='home-container-about-us-cover-image' />
									</div>
									<div>
										<img src={tmpImage2} alt="About us 2" className='home-container-about-us-cover-image' />
									</div>
									<div>
										<img src={tmpImage3} alt="About us 3" className='home-container-about-us-cover-image' />
									</div>
								</Carousel>
							</Col>
							<Col xs={24} sm={24} md={24} lg={16} xl={16}>
								<div className='home-container-about-us-text-block'>
									<div className='home-container-about-us-text-header'>Poznaj naszą restaurację</div>
									<div className='home-container-about-us-text-description'>
										Zapraszamy do naszej restauracji na wyśmienite dania kuchni indyjskiej.
										Zabierzemy Państwa w niesamowitą przygodę, w której odkryjecie klasyczne smaki
										tej kuchni. Menu jest pełne pięknie przygotowanych specjalności wywodzących się
										z różnych stron Indii. Jeśli kochasz kuchnię indyjską, to pokochasz to, co
										robimy… naszym celem jest, abyś spędził czas w niezapomnianej atmosferze, przy
										pysznych daniach.
									</div>
									<Row justify='end'>
										<Button
											onClick={showRestaurantsHandler}
											className='home-container-about-us-button'
											size={'large'}
										>
											Sprawdź nasze menu
										</Button>
									</Row>
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja nasze menu */}
			<Row justify='start' ref={menuPageRef}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} className='home-container-menu-image-block'>
					<Row>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<div>
								<Row justify='center'>
									<img src={menuDishImage} alt="Menu dish" style={{ height: '95px', marginTop: '100px' }} />
								</Row>

								<Row justify='center'>
									<div className='home-container-menu-header'>Sprawdź nasze menu</div>
								</Row>
							</div>
						</Col>
					</Row>

					<Row justify='center'>
						<Col xs={24} sm={7} md={7} lg={5} xl={5}>
							<div className='home-container-menu-card-block'>
								<Card
									className='home-container-menu-card'
									hoverable
									style={{ width: '100%' }}
									cover={<img src={menuCardImage1} alt="Menu Card 1" />}
								>
									<Row justify='center'>
										<Meta title='Przystawki' />
									</Row>
									<Row justify='center'>
										<Button
											onClick={showMenuRestaurantHandler}
											className='home-container-menu-card-button'
											size={'small'}
										>
											Więcej
										</Button>
									</Row>
								</Card>
							</div>
						</Col>

						<Col xs={24} sm={7} md={7} lg={5} xl={5}>
							<div className='home-container-menu-card-block'>
								<Card
									className='home-container-menu-card'
									hoverable
									style={{ width: '100%' }}
									cover={<img src={menuCardImage2} alt="Menu Card 2" />}
								>
									<Row justify='center'>
										<Meta title='Dania główne' />
									</Row>
									<Row justify='center'>
										<Button
											onClick={showMenuRestaurantHandler}
											className='home-container-menu-card-button'
											size={'small'}
										>
											Więcej
										</Button>
									</Row>
								</Card>
							</div>
						</Col>

						<Col xs={24} sm={7} md={7} lg={5} xl={5}>
							<div className='home-container-menu-card-block'>
								<Card
									className='home-container-menu-card'
									hoverable
									style={{ width: '100%' }}
									cover={<img src={menuCardImage3} alt="Menu Card 3" />}
								>
									<Row justify='center'>
										<Meta title='Dodatki' />
									</Row>
									<Row justify='center'>
										<Button
											onClick={showMenuRestaurantHandler}
											className='home-container-menu-card-button'
											size={'small'}
										>
											Więcej
										</Button>
									</Row>
								</Card>
							</div>
						</Col>
					</Row>

					<Row justify='center'>
						<span className='home-container-menu-promotions' onClick={promotionsHandler}>
							Sprawdź aktualne promocje
						</span>
					</Row>
				</Col>
			</Row>

			{/* Sekcja informacje o resauracji */}
			<Row justify='center' ref={contactPageRef}>
				<Col xs={22} sm={18} md={14} lg={18} xl={18}>
					<Row justify='center'>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Row justify='center' className='home-container-photo-gallery-block-header'>
								Kontakt
							</Row>
							<Row justify='center'>
								<div className='home-container-photo-gallery-block-divider'></div>
							</Row>
						</Col>
					</Row>

					<div className='home-container-restaurant-info-block'>
						<Row>
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className='home-container-restaurant-info-column-1'
							>
								<Row className='home-container-restaurant-select-restaurant-header'>
									Wybierz restaurację
								</Row>

								<Select
									className='home-container-restaurant-select-restaurant'
									defaultValue={0}
									style={{ width: 120 }}
									ref={secondSelectRestaurantRef}
									onChange={(value, event) => handleRestaurantSelectClick(value)}
									value={chosenRestaurant}
								>
									<Option value={2}>Thali na Ruskiej</Option>
									<Option value={1}>Thali na Skłodowskiej</Option>
									<Option value={0}>Thali na Jedności</Option>
									<Option value={3}>Thali Poznań</Option>
									<Option value={4}>Thali Express Kiełczów</Option>
									<Option value={6}>Thali Express Stabłowice</Option>
									<Option value={5}>Thali Food Truck</Option>
								</Select>

								<Row>
									<Button
										onClick={showMenuRestaurantHandler}
										className='home-container-restaurant-select-restaurant-button'
										size={'small'}
									>
										Zamów online
									</Button>
								</Row>
							</Col>

							<Col xs={24} sm={24} md={24} lg={7} xl={7}>
								<Row className='home-container-restaurant-info-header'>Gdzie jesteśmy</Row>
								<Row className='home-container-restaurant-info'>
									{restaurantsData[chosenRestaurant].name}
								</Row>
								<Row className='home-container-restaurant-info'>
									{restaurantsData[chosenRestaurant].addressStreet}
								</Row>
								<Row className='home-container-restaurant-info'>
									{restaurantsData[chosenRestaurant].addressCity}
								</Row>
								<Row className='home-container-restaurant-info'>
									{restaurantsData[chosenRestaurant].email}
								</Row>
								<Row className='home-container-restaurant-info'>
									{restaurantsData[chosenRestaurant].phone}
								</Row>
							</Col>

							<Col xs={24} sm={24} md={24} lg={7} xl={7}>
								<Row className='home-container-restaurant-opening-hours-header'>Godziny otwarcia</Row>
								<Row className='home-container-restaurant-opening-hours' justify='center'>
									<Col xs={8} sm={8} md={8} lg={12} xl={12}>
										Poniedziałek
									</Col>
									<Col
										xs={8}
										sm={8}
										md={8}
										lg={12}
										xl={12}
										className='home-container-restaurant-opening-hours-col'
									>
										{restaurantsData[chosenRestaurant].openingHours.monday}
									</Col>
								</Row>
								<Row className='home-container-restaurant-opening-hours' justify='center'>
									<Col xs={8} sm={8} md={8} lg={12} xl={12}>
										Wtorek
									</Col>
									<Col
										xs={8}
										sm={8}
										md={8}
										lg={12}
										xl={12}
										className='home-container-restaurant-opening-hours-col'
									>
										{restaurantsData[chosenRestaurant].openingHours.tuesday}
									</Col>
								</Row>
								<Row className='home-container-restaurant-opening-hours' justify='center'>
									<Col xs={8} sm={8} md={8} lg={12} xl={12}>
										Środa
									</Col>
									<Col
										xs={8}
										sm={8}
										md={8}
										lg={12}
										xl={12}
										className='home-container-restaurant-opening-hours-col'
									>
										{restaurantsData[chosenRestaurant].openingHours.wednesday}
									</Col>
								</Row>
								<Row className='home-container-restaurant-opening-hours' justify='center'>
									<Col xs={8} sm={8} md={8} lg={12} xl={12}>
										Czwartek
									</Col>
									<Col
										xs={8}
										sm={8}
										md={8}
										lg={12}
										xl={12}
										className='home-container-restaurant-opening-hours-col'
									>
										{restaurantsData[chosenRestaurant].openingHours.thursday}
									</Col>
								</Row>
								<Row className='home-container-restaurant-opening-hours' justify='center'>
									<Col xs={8} sm={8} md={8} lg={12} xl={12}>
										Piątek
									</Col>
									<Col
										xs={8}
										sm={8}
										md={8}
										lg={12}
										xl={12}
										className='home-container-restaurant-opening-hours-col'
									>
										{restaurantsData[chosenRestaurant].openingHours.friday}
									</Col>
								</Row>
								<Row className='home-container-restaurant-opening-hours' justify='center'>
									<Col xs={8} sm={8} md={8} lg={12} xl={12}>
										Sobota
									</Col>
									<Col
										xs={8}
										sm={8}
										md={8}
										lg={12}
										xl={12}
										className='home-container-restaurant-opening-hours-col'
									>
										{restaurantsData[chosenRestaurant].openingHours.saturday}
									</Col>
								</Row>
								<Row className='home-container-restaurant-opening-hours' justify='center'>
									<Col xs={8} sm={8} md={8} lg={12} xl={12}>
										Niedziela
									</Col>
									<Col
										xs={8}
										sm={8}
										md={8}
										lg={12}
										xl={12}
										className='home-container-restaurant-opening-hours-col'
									>
										{restaurantsData[chosenRestaurant].openingHours.sunday}
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja z galerią zdjęć */}
			<Row justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row justify='center'>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Row justify='center' className='home-container-photo-gallery-block-header'>
								Galeria
							</Row>
							<Row justify='center'>
								<div className='home-container-photo-gallery-block-divider'></div>
							</Row>
						</Col>
					</Row>

					<div className='home-container-photo-gallery-block'>
						<Row justify='center'>
							<Col xs={10} sm={7} md={7} lg={4} xl={4}>
								<Image src={imageGalleryImage1} className='home-container-photo-gallery-image' />
							</Col>

							<Col xs={10} sm={7} md={7} lg={4} xl={4}>
								<Image src={imageGalleryImage2} className='home-container-photo-gallery-image' />
							</Col>

							<Col xs={10} sm={7} md={7} lg={4} xl={4}>
								<Image src={imageGalleryImage3} className='home-container-photo-gallery-image' />
							</Col>

							<Col xs={10} sm={7} md={7} lg={4} xl={4}>
								<Image src={imageGalleryImage4} className='home-container-photo-gallery-image' />
							</Col>

							<Col xs={10} sm={7} md={7} lg={4} xl={4}>
								<Image src={imageGalleryImage5} className='home-container-photo-gallery-image' />
							</Col>

							<Col xs={10} sm={7} md={7} lg={4} xl={4}>
								<Image src={imageGalleryImage6} className='home-container-photo-gallery-image' />
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja z opiniami */}
			<Row justify='center' ref={reviewsPageRef}>
				<Col xs={22} sm={22} md={22} lg={20} xl={20}>
					<div className='home-container-reviews-block'>
						<Row justify='center'>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Row justify='center' className='home-container-reviews-block-header'>
									Opinie
								</Row>
								<Row justify='center' className='home-container-reviews-block-description'>
									Co mówią o nas klienci
								</Row>
								<Row justify='center'>
									<div className='home-container-reviews-block-divider'></div>
								</Row>
							</Col>
						</Row>

						<Row justify='center'>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Carousel autoplay dots={true}>
									<div>
										<Row justify='center' className='home-container-reviews-card-row'>
											<Col xs={22} sm={22} md={22} lg={6} xl={6}>
												<div className='home-container-reviews-card-block'>
													{/* Opinia */}
													<Row className='home-container-reviews-card-review'>
														Jak zawsze bardzo smaczne i aromatyczne. Uwielbiam i polecam.
													</Row>
													{/* Linia oddzielająca */}
													<Row>
														<div className='home-container-reviews-card-divider'></div>
													</Row>

													{/* Osoba dodająca opinie i ocena */}
													<Row justify='start'>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Row justify='start'>Kasia</Row>
														</Col>
														<Col xs={16} sm={16} md={16} lg={16} xl={16}>
															<Row justify='end'>
																<Rate
																	disabled
																	defaultValue={5}
																	className='home-container-reviews-rate'
																/>
															</Row>
														</Col>
													</Row>
												</div>
											</Col>
											<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
											<Col xs={22} sm={22} md={22} lg={6} xl={6}>
												<div className='home-container-reviews-card-block'>
													{/* Opinia */}
													<Row className='home-container-reviews-card-review'>
														Wysmienita kuchnia, smakuje świeżo i zdecydownie indyjsko.
														Zdecydownie polecam
													</Row>
													{/* Linia oddzielająca */}
													<Row>
														<div className='home-container-reviews-card-divider'></div>
													</Row>
													{/* Osoba dodająca opinie i ocena */}
													<Row justify='start'>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Row justify='start'>Maciej</Row>
														</Col>
														<Col xs={16} sm={16} md={16} lg={16} xl={16}>
															<Row justify='end'>
																<Rate
																	disabled
																	defaultValue={5}
																	className='home-container-reviews-rate'
																/>
															</Row>
														</Col>
													</Row>
												</div>
											</Col>
											<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
											<Col xs={22} sm={22} md={22} lg={6} xl={6}>
												<div className='home-container-reviews-card-block'>
													{/* Opinia */}
													<Row className='home-container-reviews-card-review'>
														Kulinarna podróż do Indii. Aromatyczne sosy, świeże dodatki,
														chrupiące samosy. Każde danie, które zamawiamy zachwyca!
													</Row>
													{/* Linia oddzielająca */}
													<Row>
														<div className='home-container-reviews-card-divider'></div>
													</Row>
													{/* Osoba dodająca opinie i ocena */}
													<Row justify='start'>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Row justify='start'>Paweł</Row>
														</Col>
														<Col xs={16} sm={16} md={16} lg={16} xl={16}>
															<Row justify='end'>
																<Rate
																	disabled
																	defaultValue={5}
																	className='home-container-reviews-rate'
																/>
															</Row>
														</Col>
													</Row>
												</div>
											</Col>
										</Row>
									</div>

									<div>
										<Row justify='center' className='home-container-reviews-card-row'>
											<Col xs={22} sm={22} md={22} lg={6} xl={6}>
												<div className='home-container-reviews-card-block'>
													{/* Opinia */}
													<Row className='home-container-reviews-card-review'>
														Bardzo smacznie Thali nie zawodzi w żadnej z lokalizacji
													</Row>
													{/* Linia oddzielająca */}
													<Row>
														<div className='home-container-reviews-card-divider'></div>
													</Row>

													{/* Osoba dodająca opinie i ocena */}
													<Row justify='start'>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Row justify='start'>Dorota</Row>
														</Col>
														<Col xs={16} sm={16} md={16} lg={16} xl={16}>
															<Row justify='end'>
																<Rate
																	disabled
																	defaultValue={5}
																	className='home-container-reviews-rate'
																/>
															</Row>
														</Col>
													</Row>
												</div>
											</Col>
											<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
											<Col xs={22} sm={22} md={22} lg={6} xl={6}>
												<div className='home-container-reviews-card-block'>
													{/* Opinia */}
													<Row className='home-container-reviews-card-review'>
														Przepyszne jedzenie, obłędna Korma, kulturalny dostawca.
														Zamówimy ponownie
													</Row>
													{/* Linia oddzielająca */}
													<Row>
														<div className='home-container-reviews-card-divider'></div>
													</Row>
													{/* Osoba dodająca opinie i ocena */}
													<Row justify='start'>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Row justify='start'>Paweł</Row>
														</Col>
														<Col xs={16} sm={16} md={16} lg={16} xl={16}>
															<Row justify='end'>
																<Rate
																	disabled
																	defaultValue={5}
																	className='home-container-reviews-rate'
																/>
															</Row>
														</Col>
													</Row>
												</div>
											</Col>
											<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
											<Col xs={22} sm={22} md={22} lg={6} xl={6}>
												<div className='home-container-reviews-card-block'>
													{/* Opinia */}
													<Row className='home-container-reviews-card-review'>
														Bardzo smaczne i szybko przywiezione jedzenie.
													</Row>
													{/* Linia oddzielająca */}
													<Row>
														<div className='home-container-reviews-card-divider'></div>
													</Row>
													{/* Osoba dodająca opinie i ocena */}
													<Row justify='start'>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Row justify='start'>Dawid</Row>
														</Col>
														<Col xs={16} sm={16} md={16} lg={16} xl={16}>
															<Row justify='end'>
																<Rate
																	disabled
																	defaultValue={5}
																	className='home-container-reviews-rate'
																/>
															</Row>
														</Col>
													</Row>
												</div>
											</Col>
										</Row>
									</div>
								</Carousel>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja z reklamą */}
			<Row justify='center'>
				<Col xs={22} sm={22} md={22} lg={18} xl={18}>
					<div className='home-container-ad-block'>
						<Row justify='center' align='middle' style={{ height: '100%' }}>
							<Col xs={24} sm={24} md={24} lg={18} xl={18} className='home-container-ad-block-card'>
								<Row justify='center'>
									<div className='home-container-ad-block-title'>
										Stwórz konto i korzystaj z promocji już dziś
									</div>
								</Row>

								<Row justify='center'>
									<div>
										<Button
											onClick={signUpHandler}
											className='home-container-ad-block-button'
											size={'small'}
										>
											Dodaj konto
										</Button>
									</div>
								</Row>

								<Row justify='center'>
									<div onClick={signInHandler} className='home-container-ad-block-footer'>
										Mam już konto
									</div>
								</Row>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			{/* Sekcja z podsumowaniem */}
			<Row justify='center' className='home-container-summary-row'>
				<Col xs={22} sm={22} md={22} lg={18} xl={18}>
					<Row align='middle' justify='center'>
						<div className='home-container-summary-block'>
							<Row justify='center'>
								<Col
									xs={20}
									sm={20}
									md={20}
									lg={11}
									xl={11}
									className='home-container-summary-general-col'
								>
									<span className='home-container-summary-general'>Co nas wyróżnia?</span>
								</Col>

								<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>

								<Col
									xs={20}
									sm={20}
									md={20}
									lg={11}
									xl={11}
									className='home-container-summary-element-col'
								>
									<Row>
										<Col
											xs={4}
											sm={4}
											md={4}
											lg={4}
											xl={4}
											className='home-container-summary-number'
										>
											01.
										</Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-header'
										>
											<span>Aromatyczne przyprawy</span>
										</Col>
									</Row>
									<Row>
										<Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-description'
										>
											To właśnie sprawia, że smak naszych dań jest tak wyjątkowy
										</Col>
									</Row>
								</Col>
							</Row>

							<Row justify='center'>
								<Col
									xs={20}
									sm={20}
									md={20}
									lg={11}
									xl={11}
									className='home-container-summary-element-col'
								>
									<Row>
										<Col
											xs={4}
											sm={4}
											md={4}
											lg={4}
											xl={4}
											className='home-container-summary-number'
										>
											02.
										</Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-header'
										>
											Tradycyjna receptura
										</Col>
									</Row>
									<Row>
										<Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-description'
										>
											Dania przygotowujemy wyłącznie według tradycyjnych receptur
										</Col>
									</Row>
								</Col>

								<Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>

								<Col
									xs={20}
									sm={20}
									md={20}
									lg={11}
									xl={11}
									className='home-container-summary-element-col'
								>
									<Row>
										<Col
											xs={4}
											sm={4}
											md={4}
											lg={4}
											xl={4}
											className='home-container-summary-number'
										>
											03.
										</Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-header'
										>
											Wygoda w zamawianiu
										</Col>
									</Row>
									<Row>
										<Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
										<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
										<Col
											xs={19}
											sm={19}
											md={19}
											lg={19}
											xl={19}
											className='home-container-summary-description'
										>
											To szybkie i proste jak nigdy, wybierz danie i zamów już teraz
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Row>
				</Col>
			</Row>

			{/* Stopka */}
			<Row justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div className='home-container-footer-block'>
						<Row>
							<Col xs={24} sm={24} md={24} lg={7} xl={7}>
								<Row justify='center' align='top' className='home-container-footer-image-row'>
									<img src={appLogoBigImage} className='home-container-footer-image' alt="App logo" />
								</Row>
							</Col>

							<Col xs={24} sm={24} md={24} lg={4} xl={4}>
								<div className='home-container-footer-menu-block'>
									<div className='home-container-footer-menu-divider'></div>
									<ul>
										<li>
											<Link href='/'>Strona główna</Link>
										</li>
										<li>
											<Link onClick={handleLandingPageScrollOnClick}>O nas</Link>
										</li>
										<li>
											<Link onClick={handleMenuScrollOnClick}>Menu</Link>
										</li>
										<li>
											<Link onClick={handleReviewsScrollOnClick}>Opinie</Link>
										</li>
										<li>
											<Link onClick={handleContactScrollOnClick}>Kontakt</Link>
										</li>
									</ul>
								</div>
							</Col>

							<Col xs={24} sm={24} md={24} lg={6} xl={6}>
								<div className='home-container-footer-menu-block'>
									<div className='home-container-footer-menu-divider'></div>
									<ul>
										<li>
											<Link onClick={signInHandler}>Zaloguj się</Link>
										</li>
										<li>
											<Link onClick={signUpHandler}>Stwórz konto</Link>
										</li>
										<li>
											<Link onClick={forgotPasswordHandler}>Zapomniałem hasła</Link>
										</li>
										<li>
											<Link onClick={() => setRulebookModalVisible(true)}>Regulamin</Link>
										</li>
										<li>
											<Link onClick={() => setPrivacyPolicyModalVisible(true)}>
												Polityka prywatności
											</Link>
										</li>
										<li>
											<Link onClick={() => marketingConsentsModalVisible(true)}>
												Zgody marketingowe
											</Link>
										</li>
									</ul>
								</div>
							</Col>

							<Col xs={24} sm={24} md={24} lg={6} xl={6}>
								<div className='home-container-footer-button-block'>
									<Row className='home-container-footer-button-block-section-1'>
										<Button
											onClick={showRestaurantsHandler}
											className='home-container-footer-button'
											size={'small'}
										>
											Zamów online
										</Button>
										<Button
											onClick={accountHandler}
											className='home-container-footer-button'
											size={'small'}
										>
											Moje konto
										</Button>
									</Row>
									<Row className='home-container-footer-button-block-section-2'>
										<span>Jesteśmy na</span>
									</Row>
									<Row className='home-container-footer-button-block-section-3'>
										<a href='https://www.facebook.com/thaliwroclove/' target='_blank' rel="noopener noreferrer">
											<img
												src={facebookLogoImage}
												style={{ objectFit: 'cover', width: '20%' }}
												alt="Facebook logo"
											/>
											<img
												src={messengerLogoImage}
												style={{ objectFit: 'cover', width: '20%' }}
												alt="Messenger logo"
											/>
										</a>
									</Row>
								</div>
							</Col>
						</Row>

						<Row justify='center'>
							<div className='home-container-footer-eresto-block'>
								Wspierany przez zespół
								<a href='https://eresto.pl/' target='_blank' rel="noopener noreferrer">
									eresto.pl
								</a>
							</div>
						</Row>
					</div>
				</Col>
			</Row>

			<Modal
				title='Regulamin'
				centered
				visible={rulebookModal}
				onOk={() => setRulebookModalVisible(false)}
				onCancel={() => setRulebookModalVisible(false)}
				width={1000}
				footer={[
					<Button key='back' onClick={() => setRulebookModalVisible(false)}>
						Ok
					</Button>,
				]}
			>
				<p>Regulamin</p>
			</Modal>

			<Modal
				title='Polityka prywatności'
				centered
				visible={privacyPolicyModal}
				onOk={() => setPrivacyPolicyModalVisible(false)}
				onCancel={() => setPrivacyPolicyModalVisible(false)}
				width={1000}
				footer={[
					<Button key='back' onClick={() => setPrivacyPolicyModalVisible(false)}>
						Ok
					</Button>,
				]}
			>
				<p>Polityka prywatności</p>
			</Modal>

			<Modal
				title='Zgody marketingowe'
				centered
				visible={marketingConsentsModal}
				onOk={() => marketingConsentsModalVisible(false)}
				onCancel={() => marketingConsentsModalVisible(false)}
				width={1000}
				footer={[
					<Button key='back' onClick={() => marketingConsentsModalVisible(false)}>
						Ok
					</Button>,
				]}
			>
				<p>Zgody marketingowe</p>
			</Modal>
		</div>
	);
};

export default HomePageContainer;
