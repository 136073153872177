import { Badge, Button, Col, Divider, Image, Radio, Row, Typography } from 'antd';
import { DeliveryMethodEnum } from 'App/domain/enums/deliveryMethodEnum';
import { cartService } from 'App/services/cartService';
import { RootState } from 'App/state/root.reducer';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import przelewy24Svg from './utils/logo-przelewy-24.svg';
import visaSvg from './utils/Visa.svg';
import blikSvg from './utils/Blik.svg';
import { PaymentMethodEnum } from 'App/domain/enums/paymentMethodEnum';
import { updateCart, updatePaymentMethodEnum } from 'App/state/newOrder/newOrder.slice';
import { green } from '@ant-design/colors';
import { promotionService } from 'App/services/promotionService';
import './StepSummary.less';
import AddDiscountCodeModal from './modals/AddDiscountCodeModal';
import { BarcodeOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const StepSummary = () => {
	const dispatch = useDispatch();

	const [addDiscountCodeModalVisibility, setAddDiscountCodeModalVisibility] = useState(false)

	const cart = useSelector((state: RootState) => state.newOrder.cart);
	const deliveryMethodEnum = useSelector((state: RootState) => state.newOrder.deliveryMethodEnum);
	const paymentMethodEnum = useSelector((state: RootState) => state.newOrder.paymentMethodEnum);
	const restaurant = useSelector((state: RootState) => state.newOrder.restaurant);
	const tableNumber = useSelector((state: RootState) => state.newOrder.tableNumber);
	const userAddress = useSelector((state: RootState) => state.newOrder.userAddress);

	const { delivery, self_pickup, to_table } = DeliveryMethodEnum;
	const { blik, credit_card, fast_transfer, in_restaurant } = PaymentMethodEnum;

	const totalPrice = cartService.getCartPrice(cart);
	const allDistinctPromotions = cartService.getAllDistinctPromotions(cart);

	const isCouponSelected = cart?.promotions[0] != null && cart.promotions[0].promotion_type === "DISCOUNT_CODE";
	const selectedPromotion = cart?.promotions[0] != null ? cart.promotions[0] : null;

	const addDiscountCodeModalOnClose = () => {
		setAddDiscountCodeModalVisibility(false)
	}

	return (
		<>
			<AddDiscountCodeModal
				handleClose={addDiscountCodeModalOnClose}
				visible={addDiscountCodeModalVisibility}
			/>
			<Col xs={24} xl={22} xxl={20} className='px-2'>
				<Row justify='center'>
					<Col xs={24}>

						<Row justify='center' className="step-summary-top-row">
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='text-center'>
								<span className="step-summary-restaurant-name">{restaurant.name}</span>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='text-center'>
								<span className="step-summary-restaurant-address">
									{restaurant.address}, {restaurant.city}
								</span>
							</Col>
						</Row>

						<Row>
							<Divider />
						</Row>
						<Row>
							<Title level={5}>Promocje:</Title>
						</Row>
						<Row>
							<Button
								className="step-summary-add-discount-code-promotion-button"
								onClick={() => setAddDiscountCodeModalVisibility(true)}
							>
								Dodaj kod promocyjny
						</Button>
						</Row>
						<Row>
							{allDistinctPromotions?.length === 0 ? (
								<Title level={5}>Brak dostępnych promocji</Title>
							) : (
								<Col>
									{allDistinctPromotions.map((promotion) => {
										return (
											<Row key={promotion.id}>
												<Row align='middle'>
													<Col>
														<Radio
															value={promotion}
															checked={promotion.id === cart.promotions[0]?.id}
															//e.target.checked
															//e.target.value
															onChange={(e) => {
																e.target.checked &&
																	dispatch(
																		updateCart({
																			...cart,
																			promotions: [e.target.value],
																		})
																	);
															}}
														/>
													</Col>
												</Row>
												<Row align='middle' gutter={[16, 0]}>
													<Col>
														<Row>
															<Text strong>{promotion.title}</Text>
														</Row>
														<Row>
															<Text type='secondary'>{promotion.description}</Text>
														</Row>
													</Col>
													<Col>
														<Badge
															count={promotionService.getTextToDisplay(promotion)}
															style={{ backgroundColor: green[5] }}
														/>
													</Col>
												</Row>
											</Row>
										);
									})}
								</Col>
							)}
						</Row>
						{isCouponSelected &&
							<>
								<Row>
									<Divider />
								</Row>
								<Row>
									<Text strong>Wybrany kod promocyjny:</Text>
								</Row>
								<Row align='middle' gutter={[16, 0]}>
									<Row align='middle'>
										<Col>
											<BarcodeOutlined className="step-summary-add-discount-code-promotion-icon" />
										</Col>
									</Row>
									<Col>
										<Row>
											<Text strong>{selectedPromotion.title}</Text>
										</Row>
										<Row>
											<Text type='secondary'>{selectedPromotion.description}</Text>
										</Row>
									</Col>
									<Col>
										<Badge
											count={promotionService.getTextToDisplay(selectedPromotion)}
											style={{ backgroundColor: green[5] }}
										/>
									</Col>
								</Row>
							</>
						}
						<Row>
							<Divider />
						</Row>
						<Row>
							<Title level={5}>Dostawa:</Title>
						</Row>
						<Row>
							{deliveryMethodEnum === delivery && (
								<Col>
									<Row>
										<Text strong>Dostawa na adres:</Text>
									</Row>
									<Row>
										<Text>
											{userAddress?.address_1} {userAddress?.address_2} {userAddress?.city}
										</Text>
									</Row>
								</Col>
							)}
							{deliveryMethodEnum === self_pickup && (
								<Col>
									<Row>
										<Text strong>Odbiór własny</Text>
									</Row>
								</Col>
							)}
							{deliveryMethodEnum === to_table && (
								<Col>
									<Row>
										<Text strong>Do stolika: {tableNumber}</Text>
									</Row>
								</Col>
							)}
						</Row>
						<Row>
							<Divider />
						</Row>
						<Row>
							<Title level={5}>Podsumowanie koszyka:</Title>
						</Row>
						<Row>
							<Text strong>Całkowita wartość koszyka: {totalPrice.toFixed(2)} PLN</Text>
						</Row>
						<Row>Dostawa: {cart.delivery_cost_in_pln.toFixed(2)} PLN</Row>
						<Row>
							Zniżka:
							<span className="pl-1"></span>
							<Text strong type="success">{cart?.promotions[0] ? promotionService.getTextToDisplay(cart.promotions[0]) : '-0.00 PLN'}</Text>

						</Row>
						<Row>
							<Divider />
						</Row>
						<Row>
							<Title level={5}>Metoda płatności:</Title>
						</Row>
						<Row>
							<Radio.Group
								onChange={(e) => dispatch(updatePaymentMethodEnum(e.target.value))}
								value={paymentMethodEnum}
							>
								<Col>
									{deliveryMethodEnum === DeliveryMethodEnum.to_table && (
										<Row className='mb-2' justify='space-between'>
											<Col>
												<Radio value={in_restaurant}>W restauracji</Radio>
											</Col>
											<Col span={4}></Col>
										</Row>
									)}
									<Row className='mb-2' justify='space-between'>
										<Col>
											<Radio value={fast_transfer}>Szybki przelew</Radio>
										</Col>
										<Col span={4}>
											<Image src={przelewy24Svg} />
										</Col>
									</Row>
									<Row className='mb-2' justify='space-between'>
										<Col>
											<Radio value={credit_card}>Karta płatnicza</Radio>
										</Col>
										<Col span={4}>
											<Image src={visaSvg} />
										</Col>
									</Row>
									<Row className='mb-2' justify='space-between'>
										<Col>
											<Radio value={blik}>Blik</Radio>
										</Col>
										<Col span={4}>
											<Image src={blikSvg} />
										</Col>
									</Row>
								</Col>
							</Radio.Group>
						</Row>
						<Row className='mt-2' justify='center'>
							Płatność obsługiwana przez system Przelewy24
					</Row>
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default StepSummary;
