import { Rule } from 'antd/lib/form';

export const anonymousUserOrderFormRules = {
	name: (): Rule[] => [{ required: true, max: 255 }],
	last_name: (): Rule[] => [{ required: true, max: 255 }],
	phone_number: (): Rule[] => [
		{ required: true, max: 255 },
		{
			pattern: RegExp('^([0-9()/+ -]*)$'),
			message: 'Pole musi być poprawnym numerem telefonu',
		},
	],
	email: (): Rule[] => [{ required: true, max: 255, type: 'email' }],
};
