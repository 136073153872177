import { requests } from '../../agent/agent';
import { GetAnonymousOrderResponse } from './responses/getAnonymousOrderResponse';
import { GetUserOrderResponse } from './responses/getUserOrderResponse';
import { OrderForGetUserOrdersResponse } from './responses/getUserOrdersResponse';

export const UserOrdersApi = {
	addOrder: (body: any): Promise<any> => requests.post('/client/orders-web', body),

	addAnonymousOrder: (body: any): Promise<any> => requests.post('/client/orders-web-anonymous', body),

	getUserOrders: (): Promise<OrderForGetUserOrdersResponse[]> => requests.get('/client/orders'),

	getUserOrder: (orderId: number): Promise<GetUserOrderResponse> => requests.get(`/client/orders/${orderId}`),

	getAnonymousOrder: (anonymousOrderId: string): Promise<GetAnonymousOrderResponse> =>
		requests.get(`/client/orders-anonymous/${anonymousOrderId}`),
};
