import { urlsToWhichNotReturn } from 'app.config';
import { RootState } from 'App/state/root.reducer';
import Role from 'App/types/role';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteChildrenProps, RouteProps, useHistory } from 'react-router';
import LoadingScreen from './LoadingScreen';

interface ProtectedRouteProps extends RouteProps {
	acceptedRoles?: Role[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
	const token = useSelector((state: RootState) => state.auth.tokens?.token);
	const appStarting = useSelector((state: RootState) => state.auth.flags.appStarting);
	const login = useSelector((state: RootState) => state.auth.flags.login);

	const history = useHistory();

	const { component: Component, ...rest } = props;

	if (appStarting || login) return <LoadingScreen container='screen' />;
	else {
		//jesli nie ma accountDetails lub token
		if (!token) {
			let browserUrl = history.location.pathname;
			if (!urlsToWhichNotReturn.some((url) => browserUrl.includes(url)))
				localStorage.setItem('returnUrl', browserUrl);
			return <Redirect to={{ pathname: '/sign-in', state: props.location }} />;
		} else if (token) return <Route {...rest} render={(props: RouteChildrenProps) => <Component {...props} />} />;
		//jeśli nie to przekieruj na forbbiden (użytkownik jest zalogowany ale nie ma dostępu do zasobu)
		else return <Redirect to={{ pathname: '/403', state: props.location }} />;
	}
};

export default ProtectedRoute;
