import { baseFilesURL } from 'App/api/agent/axios/configuration';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';

export const getPromotionImgUrl = (promotion: PromotionForGetAllUserPromotions) => {
	if (promotion.image) {
		return `${baseFilesURL}/uploads/images/${promotion.image.url}`;
	}

	return require('../../../common/assets/products/tikka_masala_2_placeholder.jpg');
};
