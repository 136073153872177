import { Badge, Card, Col, Row, Typography } from 'antd';
import { OrderForGetUserOrdersResponse } from 'App/api/endpoints/userOrders/responses/getUserOrdersResponse';
import { orderStatusMapper } from 'App/api/mappers/orderStatusMapper';
import { OrderStatusEnum } from 'App/domain/enums/orderStatusEnum';
import React from 'react';
import './OrderListCard.less';

interface OrderListCardProps {
	order: OrderForGetUserOrdersResponse;
}
const OrderListCard: React.FC<OrderListCardProps> = (props) => {
	const { order } = props;

	const getDayOfOrder = () => {
		return new Date(order.date_created).toLocaleDateString('pl-PL', {
			day: '2-digit',
		});
	};

	const getMonthOfOrder = () => {
		return new Date(order.date_created).toLocaleDateString('pl-PL', {
			month: 'short',
		});
	};

	const getTotalProductsOfOrder = () => {
		let total = 0;
		order.ordered_products.forEach((op) => {
			total += op.count;
		});
		return total;
	};

	const getOrderStatus = () => {
		const orderStatus = orderStatusMapper(order.order_status.name);

		switch (orderStatus) {
			case OrderStatusEnum.new_order:
				return 'Złożone';

			case OrderStatusEnum.in_preparation:
				return 'W przygotowaniu';

			case OrderStatusEnum.cancel:
				return 'Anulowane';

			case OrderStatusEnum.error:
				return 'Błąd';

			case OrderStatusEnum.in_transport:
				return 'W drodze';

			case OrderStatusEnum.finished:
				return 'Zakończone';

			case OrderStatusEnum.paid:
				return 'Zapłacone';

			case OrderStatusEnum.ready:
				return 'Gotowe do odbioru';

			default:
				return '';
		}
	};

	return (
		<Card className='order-list-card'>
			<Row align='middle'>
				<Col xs={4} sm={2} lg={2}>
					<Row>
						<Col xs={24}>
							<Typography.Text strong>{getDayOfOrder()}</Typography.Text>
						</Col>
						<Col xs={24}>
							<Typography.Text strong>{getMonthOfOrder()}</Typography.Text>
						</Col>
					</Row>
				</Col>
				<Col xs={11} sm={17} lg={17}>
					<Row>
						<Col xs={24}>{order.place.name}</Col>
						<Col xs={24}>
							<Typography.Text type='secondary'>
								{`Zamówienie ${order.number}  Produkty: ${getTotalProductsOfOrder()}`}
							</Typography.Text>
						</Col>
					</Row>
				</Col>
				<Col xs={9} sm={5}>
					<Row justify='end'>
						<Col xs={24} className='text-right'>
							{(order.price_pln + order.delivery_price).toFixed(2)} PLN
						</Col>
						<Col xs={24} className='text-right'>
							<Badge count={getOrderStatus()} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
};

export default OrderListCard;
