import { CheckCircleOutlined, ClockCircleOutlined, FireFilled, HeartFilled } from '@ant-design/icons';
import { Avatar, Badge, Card, Empty, List, Modal, Tabs, Tag, Tooltip } from 'antd';
import { ProductForGetAllProductsCategoriesResponse } from 'App/api/endpoints/productCategories/responses/getAllProductsCategoriesResponse';
import { GetRestaurantResponse } from 'App/api/endpoints/restaurants/responses/getRestaurantResponse';
import React, { useState } from 'react';
import './styles/RestaurantMenuCard.less';
import DetailedMenuProduct from '../detailedMenuProduct/DetailedMenuProduct';
import { getProductImgUrl } from '../utils/getProductImgUrl';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';

interface RestaurantMenuCardProps {
	showRibbon?: boolean;
	productCategories: ProductForGetAllProductsCategoriesResponse[];
	restaurantMenu: any;
	restaurantInfo: GetRestaurantResponse;
	loading: boolean;
	productIdsWithValidPromotions: {
		productId: number;
		promotions: PromotionForGetAllUserPromotions[];
	}[];
}

const RestaurantMenuCard: React.FC<RestaurantMenuCardProps> = (props: RestaurantMenuCardProps) => {
	const {
		showRibbon,
		productCategories,
		restaurantMenu,
		restaurantInfo,
		loading,
		productIdsWithValidPromotions,
	} = props;

	const [isProductDetailsModalVisible, setIsProductDetailsModalVisible] = useState(false);
	const [productToShowInModal, setProductToShowInModal] = useState(null);

	const getSortedProductCategories = () => {
		if (!productCategories) {
			return [];
		}

		const sortedProductCategories = [...productCategories].sort((a, b) => a.menu_order - b.menu_order);
		return sortedProductCategories;
	};

	const openProductDetailsModal = (product: any) => {
		setIsProductDetailsModalVisible(true);
		setProductToShowInModal(product);
	};

	const closeProductDetailsModal = () => {
		setIsProductDetailsModalVisible(false);
		setProductToShowInModal(null);
	};

	const getValidPromotionsForProduct = (product: any) => {
		// jeśli produkt nie istnieje, to nie ma promocji
		if (!product || !product.id) {
			return [];
		}

		// pobieramy indeks produktu z arrayki produktów z promocjami
		const productIndexInValidPromotionsArray = productIdsWithValidPromotions.findIndex(
			(p) => p.productId === product.id
		);

		// jeśli nie znaleziono takiego produktu to nie ma promocji
		if (productIndexInValidPromotionsArray < 0) {
			return [];
		}

		// jeśli znaleziono promocje obejmujące produkt, to dopisujemy id
		// produktu do listy produktów, wśród których ma być pokazana promocja
		if (productIdsWithValidPromotions[productIndexInValidPromotionsArray].promotions.length > 0) {
			return productIdsWithValidPromotions[productIndexInValidPromotionsArray].promotions;
		}

		return [];
	};

	const backgroundImageUrl = require('../../../common/assets/restaurant-placeholder.jpg');

	const card = (
		<Card
			className='menu'
			headStyle={{
				backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
								 url(${backgroundImageUrl})`,
			}}
			title={restaurantInfo?.name}
			loading={loading}
		>
			<Tabs defaultActiveKey='0'>
				{getSortedProductCategories().length > 0 &&
					getSortedProductCategories()?.map((spc) => {
						const products = restaurantMenu.categories
							?.find((c) => c.id === spc.id)
							?.products?.filter((p) => p.is_active);

						if (products && products.length > 0) {
							return (
								<Tabs.TabPane tab={spc.name} key={spc.menu_order}>
									<List
										loading={loading}
										dataSource={products}
										locale={{
											emptyText: (
												<Empty
													description={'Brak produktów'}
													image={require('../../../common/assets/empty-placeholder-bw.jpg')}
													imageStyle={{
														height: 60,
													}}
												/>
											),
										}}
										renderItem={(product: any) => renderProduct(product)}
									></List>
								</Tabs.TabPane>
							);
						} else {
							return <React.Fragment key={spc.id}></React.Fragment>;
						}
					})}
			</Tabs>
			{getSortedProductCategories().length <= 0 && (
				<Empty
					description={'Brak potraw'}
					image={require('../../../common/assets/empty-placeholder-bw.jpg')}
					imageStyle={{
						height: 60,
					}}
				/>
			)}
		</Card>
	);

	const renderPromotionIfNeeded = (product: any) => {
		if (!product || !product.id) {
			return <></>;
		}

		const productIndexInValidPromotionsArray = productIdsWithValidPromotions.findIndex(
			(p) => p.productId === product.id
		);

		if (productIndexInValidPromotionsArray < 0) {
			return <></>;
		}

		if (productIdsWithValidPromotions[productIndexInValidPromotionsArray].promotions.length > 0) {
			return (
				<Tag color='volcano' className='ml-2'>
					Promocja
				</Tag>
			);
		}

		return <></>;
	};

	const renderProduct = (product: any): React.ReactNode => {
		return (
			<List.Item
				className='menu__list-item'
				onClick={() => {
					openProductDetailsModal(product);
				}}
			>
				<List.Item.Meta
					avatar={<Avatar src={getProductImgUrl(product.product)} />}
					title={
						<div>
							{product.product.name}
							{renderPromotionIfNeeded(product.product)}
						</div>
					}
					description={
						<>
							{product.product.description && <span>{product.product.description}</span>}
							<p>
								{product.product.is_vegan && (
									<span className='menu__attribute'>
										<Tooltip title='Vegan'>
											<CheckCircleOutlined className='menu__vegan' />
										</Tooltip>
									</span>
								)}
								{product.product.is_long_to_prepare && (
									<span className='menu__attribute'>
										<Tooltip title='Potrawa długa w przygotowaniu'>
											<ClockCircleOutlined className='menu__long-to-prepare' />
										</Tooltip>
									</span>
								)}
								{product.product.is_spicy1 && (
									<span className='menu__attribute'>
										<Tooltip title='Ostry'>
											<FireFilled className='menu__spicy1' />
										</Tooltip>
									</span>
								)}
								{product.product.is_spicy2 && (
									<span className='menu__attribute'>
										<Tooltip title='Ostrzejszy'>
											<FireFilled className='menu__spicy2' />
										</Tooltip>
									</span>
								)}
								{product.product.is_spicy3 && (
									<span className='menu__attribute'>
										<Tooltip title='Najostrzejszy'>
											<FireFilled className='menu__spicy3' />
										</Tooltip>
									</span>
								)}
							</p>
						</>
					}
				/>
				<div className='menu__price'>{`${product.product.price_pln.toFixed(2)} PLN`}</div>
			</List.Item>
		);
	};

	return (
		<>
			<Modal
				title={productToShowInModal && productToShowInModal.product.name}
				visible={isProductDetailsModalVisible}
				onOk={closeProductDetailsModal}
				onCancel={closeProductDetailsModal}
				destroyOnClose
				footer={null}
			>
				<DetailedMenuProduct
					product={productToShowInModal?.product}
					validPromotionsForProduct={getValidPromotionsForProduct(productToShowInModal?.product)}
					restaurant={restaurantInfo}
					closeModal={closeProductDetailsModal}
				/>
			</Modal>
			{showRibbon ? <Badge.Ribbon text={<HeartFilled />}>{card}</Badge.Ribbon> : card}
		</>
	);
};

export default RestaurantMenuCard;
