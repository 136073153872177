import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { FormProps } from 'antd/lib/form/Form';
import { loginFormRules } from '../utils/loginPageFormRules';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface LoginFormProps extends FormProps {
	loading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);
	const { loading, ...rest } = props;

	return (
		<Form {...rest}>
			<Form.Item name='email' messageVariables={{ arg: t('form:Common.User.Labels.Email') }} rules={loginFormRules.email()}>
				<Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder={t('form:Common.User.Placeholders.Email')} />
			</Form.Item>
			<Form.Item name='password' messageVariables={{ arg: t('form:Common.User.Labels.Password') }} rules={loginFormRules.password()}>
				<Input
					prefix={<LockOutlined className='site-form-item-icon' />}
					type='password'
					placeholder={t('form:Common.User.Placeholders.Password')}
				/>
			</Form.Item>
			<Row justify="start">
				<Col span={12}>
					<Row justify="start">
						<Link to='account/forgot-password'>
							{t('LoginPage.LoginForm.ForgotPassword')}
						</Link>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="end">
						<Form.Item>
							<Button loading={loading} type='primary' htmlType='submit' size='large'>
								{t('common:Actions.SignIn')}
							</Button>
						</Form.Item>
					</Row>

				</Col>
			</Row>
		</Form>
	);
};

export default LoginForm;
