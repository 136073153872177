import { RestaurantForGetAllRestaurantsResponse } from 'App/api/endpoints/restaurants/responses/getAllRestaurantsResponse';
import addDays from './addDays';

export const isRestaurantOpen = (restaurant: RestaurantForGetAllRestaurantsResponse) => {
	const now = new Date();
	// jeśli restauracja nieaktywna
	if (!restaurant.is_active) {
		return false;
	}

	const currentDayOfWeek = now.getDay(); // dzień w UTC, Sunday = 0, Mon = 1
	const allDaysFromBackend = ['sunday', 'monday', 'tuesday', 'wednsday', 'thursday', 'friday', 'saturday'];

	// jeśli dziś nie jest otwarta
	if (!restaurant.time_open.hasOwnProperty(allDaysFromBackend[currentDayOfWeek])) {
		return false;
	}

	// pobieramy w jakich godzinach dziś restauracja jest otwarta
	const timeOpenUtc = restaurant.time_open[allDaysFromBackend[currentDayOfWeek]].time_open;
	const timeCloseUtc = restaurant.time_open[allDaysFromBackend[currentDayOfWeek]].time_close;

	// czasy są w UTC, chcemy je przekonwertować na czas lokalny i następnie porównać
	const openLocale = new Date(`${now.toDateString()} ${timeOpenUtc}Z`);
	let closeLocale = new Date(`${now.toDateString()} ${timeCloseUtc}Z`);

	// jeśli data zamknięcia jest wcześniejsza, niż data otworzenia to najprawdopodobniej
	// mamy do czynienia z UTC i sytuacją np. otwarcie 0:00 do 23:59 (w utc 22:00 do 21:59)
	// wtedy dodajemy do daty zamknięcia jeden dzień
	if (closeLocale < openLocale) {
		closeLocale = addDays(closeLocale, 1);
	}

	// wszystkie sprowadzamy do lokalnego date i porównujemy

	// jeśli teraz jest przed otwarciem lub po zamknięciu
	// obie daty sprowadzamy do minut w obrębie dnia i na tej podstawie sprawdzamy

	const openLocaleMinutes = getMinutesOfTime(openLocale);
	const closeLocaleMinutes = getMinutesOfTime(closeLocale);
	const nowMinutes = getMinutesOfTime(now);

	if (nowMinutes < openLocaleMinutes || nowMinutes > closeLocaleMinutes) {
		return false;
	}

	// w przeciwnym razie wpadamy w otwarcie - zamknięcie
	return true;
};

export const getMinutesOfTime = (date: Date) => {
	const minutes = date.getMinutes();
	const hoursAsMinutes = date.getHours() * 60;

	const totalMinutes = hoursAsMinutes + minutes;
	return totalMinutes;
};
