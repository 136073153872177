import { GetAccountDetailsResponse, GetUserAddressesResponse } from 'App/api/endpoints/account/responses';
import StatusType from 'App/types/requestStatus';
const { INITIAL } = StatusType;

export interface AccountState {
	status: {
		confirmEmail: StatusType;
		forgotPassword: StatusType;
		updateAccountDetails: StatusType;
		updateAccountPhoneNumber: StatusType;
		changePassword: StatusType;
		addUserAddress: StatusType;
		updateUserAddress: StatusType;
		deleteUserAddress: StatusType;
		getUserAddresses: StatusType;
		deleteAccount: StatusType;
		getAccountDetails: StatusType;
	};
	accountDetails: GetAccountDetailsResponse | null;
	userAddresses: GetUserAddressesResponse[];
}

export const accountInitialState: AccountState = {
	status: {
		confirmEmail: INITIAL,
		forgotPassword: INITIAL,
		updateAccountDetails: INITIAL,
		updateAccountPhoneNumber: INITIAL,
		changePassword: INITIAL,
		addUserAddress: INITIAL,
		updateUserAddress: INITIAL,
		deleteUserAddress: INITIAL,
		getUserAddresses: INITIAL,
		deleteAccount: INITIAL,
		getAccountDetails: INITIAL,
	},
	accountDetails: null,
	userAddresses: [],
};
