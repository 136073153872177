import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';

export const getDiscountText = (promotion: PromotionForGetAllUserPromotions) => {
	let discountText = `${promotion.discount} ` || '';
	if (promotion.discount_type === 'PLN') {
		discountText += 'PLN';
	} else if (promotion.discount_type === 'PERCENT') {
		discountText += '%';
	}

	return discountText;
};
