import ProtectedRoute from 'App/common/components/ProtectedRoute';
import React from 'react';
import { Route, Switch } from 'react-router';
import OrderDetails from './orderDetails/OrderDetails';
import OrdersList from './ordersList/OrdersList';

const OrdersPageContainer: React.FC<{}> = () => {
	return (
		<Switch>
			<ProtectedRoute exact path='/orders' component={OrdersList} />
			<Route exact path='/orders/:orderId' component={OrderDetails} />
		</Switch>
	);
};

export default OrdersPageContainer;
