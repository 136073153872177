import { Badge, Col, Divider, Row, Typography } from 'antd';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import { getDiscountText } from 'App/pages/PromotionsPage/utils/getDiscountText';
import React from 'react';
import './styles/ProductPromotionsSection.less';

interface ProductPromotionsSectionProps {
	promotionProducts: PromotionForGetAllUserPromotions[];
}

const ProductPromotionsSection: React.FC<ProductPromotionsSectionProps> = (props: ProductPromotionsSectionProps) => {
	const { promotionProducts } = props;

	if (promotionProducts.length > 0) {
		return (
			<Row>
				<Col xs={24}>
					<Typography.Title level={4} type='secondary'>
						Dostępne promocje
					</Typography.Title>
				</Col>
				{promotionProducts.map((promotion) => (
					<Col xs={24} key={promotion.id} className='product-promotion'>
						<span className='product-promotion__name'>
							<Typography.Title className='product-promotion__name__title mr-2' level={5}>
								{promotion.title}
							</Typography.Title>
							<Badge count={getDiscountText(promotion)} className='product-promotion__name__badge' />
						</span>
						{promotion.minimal_cart_value_in_pln > 0 && (
							<>
								<Typography.Title type='secondary' level={5}>
									Minimalna wartość zamówienia: {promotion.minimal_cart_value_in_pln} PLN
								</Typography.Title>
							</>
						)}

						{promotion.minimal_cart_count > 0 && (
							<>
								<Typography.Title type='secondary' level={5}>
									Minimalna ilość w koszyku: {promotion.minimal_cart_count} szt
								</Typography.Title>
							</>
						)}
						<Divider className='mx-0 my-2' />
					</Col>
				))}

				<Col></Col>
			</Row>
		);
	} else {
		// nie ma promocji
		return <></>;
	}
};

export default ProductPromotionsSection;
