import React from 'react';
import { Route, Switch } from 'react-router';
import RestaurantList from './restaurantsList/RestaurantList';

const RestaurantsPageContainer: React.FC<{}> = () => {
	return (
		<Switch>
			<Route exact path='/restaurants' component={RestaurantList} />
		</Switch>
	);
};

export default RestaurantsPageContainer;
