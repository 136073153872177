import React from 'react';
import { Result, Button } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface ForbbidenPageContainerProps extends RouteComponentProps { }

const ForbiddenPageContainer: React.FC<ForbbidenPageContainerProps> = ({ history }: ForbbidenPageContainerProps) => {
    const buttonGoBackHomeOnClick = () => history.push('/');
    const { t } = useTranslation(['page', 'common']);

    return (
        <Result
            status='403'
            title='403'
            subTitle={t('ForbiddenPage.ForbiddenPageContainer.WeAreSorry')}
            extra={
                <Button type='primary' onClick={buttonGoBackHomeOnClick}>
                    {t('common:Actions.BackToHome')}
                </Button>
            }
        ></Result>
    );
};

export default ForbiddenPageContainer;
