import { requests } from '../../agent/agent';
import { RestaurantForGetAllRestaurantsResponse } from './responses/getAllRestaurantsResponse';
import { GetRestaurantResponse } from './responses/getRestaurantResponse';

export const RestaurantsApi = {
	getAllRestaurants: (): Promise<RestaurantForGetAllRestaurantsResponse[]> => {
		return requests.get(`places`)
	},

	getRestaurant: (restaurantId: number): Promise<GetRestaurantResponse> => {
		return requests.get(`places/${restaurantId}`)
	}
};