import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import StatusType from 'App/types/requestStatus';
import { promotionsInitialState, PromotionsState } from './promotions.state';

const { FAILED, SUCCESS, LOADING } = StatusType;

const promotionsSlice = createSlice({
	name: 'promotions',
	initialState: promotionsInitialState,
	reducers: {
		getAllUserPromotionsStart: (state: PromotionsState) => {
			state.status.getAllUserPromotions = LOADING;
			state.promotions = promotionsInitialState.promotions;
		},
		getAllUserPromotionsSuccess: (
			state: PromotionsState,
			action: PayloadAction<PromotionForGetAllUserPromotions[]>
		) => {
			state.status.getAllUserPromotions = SUCCESS;
			state.promotions = action.payload;
		},
		getAllUserPromotionsFailure: (state: PromotionsState) => {
			state.status.getAllUserPromotions = FAILED;
		},

		addDiscountCodePromotionStart: (state: PromotionsState) => {
			state.status.addDiscountCodePromotion = LOADING;
		},
		addDiscountCodePromotionSuccess(state: PromotionsState) {
			state.status.addDiscountCodePromotion = SUCCESS;
		},
		addDiscountCodePromotionFailure(state: PromotionsState) {
			state.status.addDiscountCodePromotion = FAILED;
		},
	},
});

export default promotionsSlice;

export const {
	getAllUserPromotionsStart,
	getAllUserPromotionsSuccess,
	getAllUserPromotionsFailure,

	addDiscountCodePromotionStart,
	addDiscountCodePromotionSuccess,
	addDiscountCodePromotionFailure,
} = promotionsSlice.actions;
