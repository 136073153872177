import { Button, Card, Col, Divider, Radio, Row, Typography } from 'antd';
import { DeliveryMethodEnum } from 'App/domain/enums/deliveryMethodEnum';
import { cartService } from 'App/services/cartService';
import { geolocationService } from 'App/services/geolocationService';
import { newOrderService } from 'App/services/newOrderService';
import { restaurantService } from 'App/services/restaurantService';
import {
	changeDeliveryOrAdressEnd,
	changeDeliveryOrAdressStart,
	updateCart,
	updateDeliveryMethodEnum,
	updateIsAddressOnDeliveryZones,
	updateTableNumber,
	updateUserAddress,
} from 'App/state/newOrder/newOrder.slice';
import { RootState } from 'App/state/root.reducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnonymousUserOrderForm from './forms/AnonymousUserOrderForm';
import AddAddressModal from './modals/addAddressModal/AddAddressModal';
import ChangeAddressModal from './modals/changeAddressModal/ChangeAddressModal';

import './StepDelivery.less';

const { Title, Text } = Typography;

const StepDelivery = () => {
	const dispatch = useDispatch();

	const [addAddressModalVisible, setAddAddressModalVisible] = useState(false);
	const [changeAddressModalVisible, setchangeAddressModalVisible] = useState(false);

	const cart = useSelector((state: RootState) => state.newOrder.cart);
	const deliveryMethodEnum = useSelector((state: RootState) => state.newOrder.deliveryMethodEnum);
	const restaurant = useSelector((state: RootState) => state.newOrder.restaurant);
	const tableNumber = useSelector((state: RootState) => state.newOrder.tableNumber);
	const userAddress = useSelector((state: RootState) => state.newOrder.userAddress);
	const isAddressOnDeliveryZones = useSelector((state: RootState) => state.newOrder.isAddressOnDeliveryZones);
	const changingDeliveryOrAddress = useSelector((state: RootState) => state.newOrder.changingDeliveryOrAddress);

	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);

	const { delivery, self_pickup, to_table } = DeliveryMethodEnum;

	const isValidForToTableOrders = restaurantService.isValidForToTableOrders(restaurant);
	const totalPrice = cartService.getCartPrice(cart);

	useEffect(() => { }, []);

	useEffect(() => {
		let accountAddresses = accountDetails?.client_information?.client_addresses;
		if (accountAddresses && accountAddresses.length !== 0) dispatch(updateUserAddress(accountAddresses[0]));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountDetails]);

	useEffect(() => {
		if (deliveryMethodEnum !== delivery) {
			dispatch(updateCart({ ...cart, delivery_cost_in_pln: 0 }));
			return;
		}

		dispatch(changeDeliveryOrAdressStart());
		if (userAddress && (deliveryMethodEnum as DeliveryMethodEnum) === delivery) {
			geolocationService
				.getGeolocationFromAddress(userAddress.address_1 + ' ' + userAddress.city)
				.then((cords) => {
					if (cords) {
						let isAnyDeliveryZone = newOrderService.isPlaceMarkInAnyOfDeliveryZones(
							cords,
							restaurant.delivery_zones
						);

						if (isAnyDeliveryZone) {
							let lowestDeliveryPrice = newOrderService.getDeliveryPriceInPlnForPlacemarkInDeliveryZones(
								cords,
								restaurant.delivery_zones
							);
							dispatch(updateCart({ ...cart, delivery_cost_in_pln: lowestDeliveryPrice }));
							dispatch(updateIsAddressOnDeliveryZones(true));
							dispatch(changeDeliveryOrAdressEnd());
						} else {
							dispatch(updateCart({ ...cart, delivery_cost_in_pln: 0 }));
							dispatch(updateIsAddressOnDeliveryZones(false));
							dispatch(changeDeliveryOrAdressEnd());
						}
					} else {
						dispatch(updateCart({ ...cart, delivery_cost_in_pln: 0 }));
						dispatch(updateIsAddressOnDeliveryZones(false));
						dispatch(changeDeliveryOrAdressEnd());
					}
				});
		} else {
			dispatch(changeDeliveryOrAdressEnd());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAddress, deliveryMethodEnum]);

	const addAddressModalShow = () => {
		setAddAddressModalVisible(true);
	};

	const addAddressModalOnClose = () => {
		setAddAddressModalVisible(false);
	};

	const changeAddressModalShow = () => {
		setchangeAddressModalVisible(true);
	};

	const changeAddressModalOnClose = () => {
		setchangeAddressModalVisible(false);
	};

	return (
		<>
			<AddAddressModal
				handleClose={addAddressModalOnClose}
				visible={addAddressModalVisible}
				userLoggedIn={accountDetails !== null}
			/>

			{accountDetails && (
				<ChangeAddressModal handleClose={changeAddressModalOnClose} visible={changeAddressModalVisible} />
			)}
			<Col xs={24} xl={22} xxl={20} className='px-2'>
				<Row justify='center'>
					<Col xs={24}>
						<Row justify='center' className='step-delivery-top-row'>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='text-center'>
								<span className='step-delivery-restaurant-name'>{restaurant.name}</span>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='text-center'>
								<span className='step-delivery-restaurant-address'>
									{restaurant.address}, {restaurant.city}
								</span>
							</Col>
						</Row>

						{!accountDetails && (
							<>
								<Divider />
								<Col xs={24}>
									<Row>
										<Col xs={24} md={16} lg={12}>
											<AnonymousUserOrderForm />
										</Col>
									</Row>
								</Col>
							</>
						)}
						<Divider />

						<Row>
							<Col xs={24}>
								<Radio.Group
									onChange={(e) => dispatch(updateDeliveryMethodEnum(e.target.value))}
									value={deliveryMethodEnum}
									className='step-delivery-radio-group'
								>
									<Radio className='step-delivery-delivery-type-radio-button' value={self_pickup}>
										Odbiór własny
									</Radio>

									<Radio className='step-delivery-delivery-type-radio-button' value={delivery}>
										Dostawa na adres
									</Radio>
									{isValidForToTableOrders && (
										<Radio className='step-delivery-delivery-type-radio-button' value={to_table}>
											Dostawa do stolika
											<Row className='mt-3'>
												<Radio.Group
													onChange={(e) => dispatch(updateTableNumber(e.target.value))}
													value={tableNumber}
												>
													{Array(restaurant.number_of_tables)
														.fill(1)
														.map((_, index) => (
															<Radio
																className='step-delivery-table-number-radio-button'
																value={index + 1}
																key={index}
															>
																{index + 1}
															</Radio>
														))}
												</Radio.Group>
											</Row>
										</Radio>
									)}
								</Radio.Group>
							</Col>

							<Col xs={24} lg={12} className='mt-3'>
								{userAddress ? (
									<>
										{deliveryMethodEnum === delivery &&
											!isAddressOnDeliveryZones &&
											!changingDeliveryOrAddress && (
												<Typography.Text type='warning'>
													Podany adres znajduje się poza zasięgiem wybranej restauracji
												</Typography.Text>
											)}
										<Card
											title={userAddress?.name}
											className='step-delivery-address-card'
											extra={
												accountDetails && (
													<Button type='link' onClick={changeAddressModalShow}>
														Zmień adres
													</Button>
												)
											}
										>
											<Row>
												<Col xs={24}>
													{userAddress.address_1 && <Text>{userAddress.address_1}</Text>}
													{userAddress.address_2 && <Text>/{userAddress.address_2}</Text>}
												</Col>
												<Col xs={24}>
													<Text>{userAddress?.city}</Text>
												</Col>
												<Col xs={24}>
													<Text>{userAddress?.comment}</Text>
												</Col>
											</Row>
										</Card>
										<Button type='link' onClick={addAddressModalShow}>
											Dodaj nowy adres
										</Button>
									</>
								) : (
									<Button type='link' onClick={addAddressModalShow}>
										Dodaj nowy adres
									</Button>
								)}
							</Col>
						</Row>
						<Row>
							<Divider />
						</Row>
						<Row>
							<Title level={5}>Całkowita wartość koszyka: {totalPrice.toFixed(2)} PLN</Title>
						</Row>
						<Row>
							<Title level={5}>Dostawa: {cart.delivery_cost_in_pln.toFixed(2)} PLN</Title>
						</Row>
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default StepDelivery;
